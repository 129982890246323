import React from 'react';


import './style.sass';


interface PCommonUsualText {
    children?: any;
    className?: string;
    isMargin?: any;
    myDangerouslySetInnerHTML?: any
    isQuote?: boolean | string
}

const CommonUsualText = ({children, className, isMargin, myDangerouslySetInnerHTML, isQuote}: PCommonUsualText) => {

    return myDangerouslySetInnerHTML ?
                <div dangerouslySetInnerHTML={{__html: myDangerouslySetInnerHTML}}
                     className={`common-usual-text ${className} ${isMargin ? 'common-usual-text--mb' : ''} ${isQuote || isQuote === "true" ? 'common-usual-text--quote' : ''}`}
                />
                :
                <div
                    className={`common-usual-text ${className} ${isMargin ? 'common-usual-text--mb' : ''} ${isQuote || isQuote === "true" ? 'common-usual-text--quote' : ''}`}
                >{children}</div>
};

export default CommonUsualText;
