import React from 'react';

interface IProps {
    imgMask: any,
    index?: number
}


const SvgMaskedIcon:React.FC<IProps> = ({imgMask, index}) => {

    return (

        <svg width="325" height="376" viewBox="0 0 325 376" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
                d="M123.158 153.333C164.322 129.566 254.056 134.724 279.281 178.415C304.507 222.107 285.15 280.509 236.048 308.858C186.945 337.207 86.8755 332.753 110.554 265.876C134.233 198.999 81.9933 177.099 123.158 153.333Z"/>
            <mask id={`mask${index}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="320" height="321">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M160.321 0.00994571C213.208 0.330064 271.25 8.90411 301.87 51.978C331.268 93.3329 320.669 150.235 300.067 196.593C283.862 233.059 242.62 243.7 207.903 263.427C167.305 286.497 130.561 333.284 86.4344 317.989C40.3212 302.005 29.5678 244.53 17.2624 197.349C4.24232 147.428 -13.6851 92.0342 16.605 50.2513C47.9236 7.04979 106.924 -0.313264 160.321 0.00994571Z"
                      fill="#CEE8E9"/>
            </mask>
            <g mask={`url(#mask${index})`}>
                <rect width="320" height="412" fill={`url(#pattern${index})`}/>
            </g>
            <defs>
                <pattern id={`pattern${index}`} patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref={`#image${index}`} transform="translate(-0.14375) scale(0.00201172 0.0015625)"/>
                </pattern>
                <image id={`image${index}`} width="640" height="640" xlinkHref={imgMask}/>
            </defs>
        </svg>
    )
};




//
// const SvgMaskedIcon:React.FC<IProps> = ({imgMask, index}) => {
//     return (
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             xmlnsXlink="http://www.w3.org/1999/xlink"
//             width="325"
//             height="376"
//             fill="none"
//             viewBox="0 0 325 376"
//         >
//             <path
//                 fill="#EFF8FD"
//                 d="M123.158 153.333c41.164-23.767 130.898-18.609 156.123 25.082 25.226 43.692 5.869 102.094-43.233 130.443-49.103 28.349-149.173 23.895-125.494-42.982 23.679-66.877-28.56-88.777 12.604-112.543z"
//             ></path>
//             <mask
//                 id={`mask${index}`}
//                 width="320"
//                 height="321"
//                 x="0"
//                 y="0"
//                 maskUnits="userSpaceOnUse"
//             >
//                 <path
//                     fill="#CEE8E9"
//                     fillRule="evenodd"
//                     d="M160.321.01C213.208.33 271.25 8.904 301.87 51.978c29.398 41.355 18.799 98.257-1.803 144.615-16.205 36.466-57.447 47.107-92.164 66.834-40.598 23.07-77.342 69.857-121.469 54.562-46.113-15.984-56.866-73.459-69.172-120.64-13.02-49.921-30.947-105.315-.657-147.098C47.924 7.05 106.924-.313 160.321.01z"
//                     clipRule="evenodd"
//                 ></path>
//             </mask>
//             <g mask={`url(#mask${index})`}>
//                 <path fill={`url(#pattern${index})`} d="M0 0H320V412H0z"></path>
//             </g>
//             <defs>
//                 <pattern
//                     id={`pattern${index}`}
//                     width="1"
//                     height="1"
//                     patternContentUnits="objectBoundingBox"
//                 >
//                     <use
//                         transform="matrix(.00201 0 0 .00156 -.144 0)"
//                         xlinkHref={`#image${index}`}
//                     ></use>
//                 </pattern>
//                 <image id={`image${index}`} width="640" height="640" xlinkHref={imgMask}></image>
//             </defs>
//         </svg>
//     );
// }



export default SvgMaskedIcon;
