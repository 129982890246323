import React from "react";


export interface IPropsTodolistMarkerBody {
    marker: {
        img: any,
        title: string
    }
}

export const TodolistMarkerBody: React.FC<IPropsTodolistMarkerBody> = ({marker}) => {
    return (
        <div className="todolist__marker-body-wrapper">
            <div className="todolist__marker-body">
                {marker.img && (
                    <div className="todolist__marker-img">
                        <img src={process.env.REACT_APP_BACK_URL + marker.img.url} alt=""/>
                    </div>
                )}
                <div className="todolist__marker-title">{marker.title}</div>
            </div>
        </div>
    );
};


