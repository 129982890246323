import React from "react";

import Gradient from "../gradient";
import ReactMarkdown from 'react-markdown';
import './style.sass';

export interface IPropsTodolistInfoItem {
    info?: any,
}

export const TodolistInfoItem: React.FC<IPropsTodolistInfoItem> = ({info}) => {
    return (
        <div className="todolist__info-item">
            {info.map((item: any, index: number) =>
                    <div key={index}>
                        <div className="todolist__info-title">
                            {
                                item.label ?
                                    <Gradient>{item.label}</Gradient>
                                    :
                                    ""
                            }
                        </div>
                        {
                            item.list ?
                                <ReactMarkdown children={item.list}/>
                                :
                                ""
                        }
                    </div>
                )
            }
        </div>
    );
};

// export TodolistInfoItem;
