import React from "react";
import "./style.sass";
import vk from "svg/vk.svg";
import youtube from "svg/youtube.svg";
import hhru from "svg/hhru.svg";
import telegram from "svg/header/telegram.svg";

export default class extends React.Component {
  render() {
    return (
      <div className="burger-social">
        <div className="burger-social__item">
          <a
            target="_blank"
            href="https://t.me/webpractik_agency"
            rel="noopener noreferrer"
            className="burger-social__link"
          >
            <img src={telegram} alt="telegram" />
          </a>
        </div>
        <div className="burger-social__item">
          <a
            target="_blank"
            href="https://vk.com/agencywebpractik"
            rel="noopener noreferrer"
            className="burger-social__link"
          >
            <img src={vk} alt="vkontakte" />
          </a>
        </div>
        <div className="burger-social__item">
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCpRYe-aT_kst84vU2XA5Y0Q"
            rel="noopener noreferrer"
            className="burger-social__link"
          >
            <img src={youtube} alt="youtube" />
          </a>
        </div>
        <div className="burger-social__item">
          <a
            target="_blank"
            href="https://rostov.hh.ru/employer/726289"
            rel="noopener noreferrer"
            className="burger-social__link"
          >
            <img src={hhru} alt="hh.ru" />
          </a>
        </div>
      </div>
    );
  }
}
