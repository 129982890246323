import React from "react";

import { ILeisure } from "../../../typings/event";
import useModal from "modules/hooks/useModal";

import EventModal from "./EventModal";

function LeisureEvent({ Img, Link, Title }: ILeisure) {
  const { onToggleModalHandler, showModal } = useModal();

  return (
    <>
      <div className="event" onClick={onToggleModalHandler}>
        <div className="event__wrapper">
          <img
            className="event__img"
            src={`${process.env.REACT_APP_BACK_URL}${Img?.url}`}
            alt={Img?.name}
          />
        </div>
        <div className="event__title">{Title}</div>
      </div>
      {Link && (
        <>
          <EventModal
            show={showModal}
            onClose={onToggleModalHandler}
            title={Title}
            link={Link}
          />
        </>
      )}
    </>
  );
}

export default LeisureEvent;
