import pochtabank from 'pic/pochtabank.png';
import ukassa from 'pic/ukassa.png';
import linkbuilder from 'pic/linkbuilder.png';
import skolkovo from 'pic/skolkovo.png';
import expocenter from 'pic/expocenter.png';
import mts from 'pic/mts.png';
import rukami from 'pic/rukami.png';
//--//svg kartinka.UP GREAT
import vostok from 'pic/vostok.png';
import generationS from 'pic/generationS.png';
import rec from 'pic/rec.png';
import onlineconvert from 'pic/onlineconvert.png';
import pdf from 'pic/pdf.png';
import nanotex from 'pic/nanotex.png';
import gge from 'pic/gge.png';
import ecosystem from 'pic/ecosystem.png';
import letovo from 'pic/letovo.png';
import zkx from 'pic/zkx.png';
import mid from 'pic/mid.png';
import technopark from 'pic/technopark.png';


export const testData = [
    {
        id: '1',
        img: pochtabank,
        title: 'Новая версия сайта Почта Банка',
        subtitle: 'Как мы перенесли сайт крупного банка на новую платформу и повысили его отказоустойчивость',
        link: 'https://webpractik.ru/cases/development/pochtabank',
    },
    {
        id: '2',
        img: ukassa,
        title: 'ЮКаssа: битва за трафик после ребрендинга',
        subtitle: 'Нам предстояло решить сложную и стратегически важную задачу — сохранить небрендовый трафик и при этом минимизировать потери позиций в поисковой выдаче',
        link: 'https://webpractik.ru/cases/marketing/yookassa',
    },
    {
        id: '3',
        img: linkbuilder,
        title: 'Linkbuilder.Ai: как ИИ меняет правила игры на рынке линкбилдинга',
        subtitle: 'Сервис для автоматизированного линкбилдинга, который упрощает и ускоряет процесс нарощения ссылочной массы сайта',
        link: 'https://webpractik.ru/cases/development/linkbuilder',
    },
    {
        id: '4',
        img: skolkovo,
        title: 'Продвижение сообщества AdventureLand Технопарка «Сколково» на венчурном рынке',
        subtitle: 'Как мы работаем, чтобы AdventureLand стал крупнейшим сообществом, в котором стартапы получают инвестиции: рекламные кампании для сложного венчурного рынка',
        link: 'https://webpractik.ru/cases/marketing/adventureland',
    },
    {
        id: '5',
        img: expocenter,
        title: 'Расширение охвата для Российского экспортного центра',
        subtitle: 'Увеличили охват, повысили количество регистраций личных кабинетов, обеспечили рост скачивания пособий с сайта РЭЦ и вывели высокочастотный ключ «Экспорт» в ТОП-10 органической выдачи',
        link: 'https://webpractik.ru/cases/marketing/rec',
    },
    {
        id: '6',
        img: mts,
        title: 'Увеличение трафика сайта для «МТС Инвестиции»',
        subtitle: 'В 4 раза увеличили показатель роста трафика на блог, в 2 раза увеличили объем проиндексированных страниц сайта и посещаемость на новые тематические посадочные страницы',
        link: 'https://webpractik.ru/cases/marketing/mts-invest',
    },
    {
        id: '7',
        img: rukami,
        title: 'Разработка интерактивной highload‑платформы для Международного киберфестиваля Rukami',
        subtitle: 'Сайт киберфестиваля посетили более 100 тысяч зрителей',
        link: 'https://webpractik.ru/cases/development/rukami',
    },
    // {
    //     id: '8',
    //     img: ,
    //     title: ,
    //     subtitle: ,
    //     link: ,
    // },
    {
        id: '9',
        img: vostok,
        title: 'Сервисы для сайта Корпорации развития Дальнего Востока',
        subtitle: 'Разработали серию сервисов для системы личных кабинетов представителей юридических лиц, которые не имеют аналогов в России',
        link: 'https://webpractik.ru/cases/development/krdv',
    },
    {
        id: '10',
        img: generationS,
        title: 'ИТ-система главного технологического акселератора страны GenerationS',
        subtitle: 'Создали площадку для проведения отбора в корпоративные акселераторы крупнейших мировых компаний',
        link: 'https://webpractik.ru/cases/development/gens',
    },
    {
        id: '11',
        img: rec,
        title: 'Поддержка и модернизация сайта и внутреннего портала РЭЦ',
        subtitle: 'Как мы 24/7 поддерживаем тех, кто поддерживает экономику страны',
        link: 'https://webpractik.ru/cases/development/dev-rec',
    },
    {
        id: '12',
        img: onlineconvert,
        title: 'Создание и запуск платформы Onlineconvertfree.com',
        subtitle: 'Запустили собственную платформу для конвертации файлов, которая поддерживает 231 формат и 2600 направлений, входит в топ-5 конвертеров в мире с аудиторией 2 млн человек из 220 стран ежемесячно',
        link: 'https://webpractik.ru/cases/development/converter',
    },
    {
        id: '13',
        img: pdf,
        title: '2pdf.com — платформа для работы с PDF-файлами',
        subtitle: 'Это собственный сервис компании для работы с PDF, который позволяет делать все необходимые операции с PDF-файлами в несколько кликов',
        link: 'https://webpractik.ru/cases/development/2pdf',
    },
    {
        id: '14',
        img: nanotex,
        title: 'Вывод ключевых слов в ТОП-3 для РМ «Нанотех»',
        subtitle: 'Увеличили посещение сайта на 30% и вывели более 1000 фраз из узкоспециализированного семантического ядра в ТОП-10 на территории 11 городов-миллионников за 6 месяцев',
        link: 'https://webpractik.ru/cases/marketing/nanoteh',
    },
    {
        id: '15',
        img: gge,
        title: 'Главгосэкспертиза России',
        subtitle: 'Это современное государственное учреждение в строительной отрасли, которое осуществляет экспертизу и надзор над ключевыми инфраструктурными объектами страны',
        link: 'https://webpractik.ru/cases/development/gge',
    },
    {
        id: '16',
        img: ecosystem,
        title: 'Экосистема форума «Глобальное технологическое лидерство»',
        subtitle: 'Сделали из сайта для привлечения ЦА к участию в форуме сложную экосистему с инструментами для моментальной передачи информации',
        link: 'https://webpractik.ru/cases/development/techleaders',
    },
    {
        id: '17',
        img: letovo,
        title: 'Модернизация и многочисленные интеграции сайта школы «Летово»',
        subtitle: 'Это школа международного уровня для одаренных детей, где преподают учителя из разных стран',
        link: 'https://webpractik.ru/cases/development/letovo',
    },
    {
        id: '18',
        img: zkx,
        title: 'Провели комплексную работу по обновлению бренда Фонда ЖКХ',
        subtitle: 'Это государственный фонд, который отвечает за реформирование ЖКХ в России',
        link: 'https://webpractik.ru/cases/development/zkhfoundation',
    },
    {
        id: '19',
        img: mid,
        title: 'Помогли Колледжу МИД России собрать в 3 раза больше заявок на поступление абитуриентов',
        subtitle: 'Обеспечили сбор не менее 500 анкет для каждого потока на поступление в Колледж МИД',
        link: 'https://webpractik.ru/cases/marketing/mid',
    },
    {
        id: '20',
        img: technopark,
        title: 'Продвижение 12 направлений Технопарка «Сколково»',
        subtitle: 'За 8 месяцев мы разработали и протестировали десятки гипотез для 12 направлений Технопарка и закрыли минимум 100% KPI по количеству лидов',
        link: 'https://webpractik.ru/cases/marketing/skolkovo',
    },
];