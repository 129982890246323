import clsx from "clsx";
import React from "react";

import PaginationPage from "./PaginationPage";

import arrowLeft from "svg/navigation-arrows/arrow-left.svg";
import arrowRight from "svg/navigation-arrows/arrow-right.svg";
import arrowLeftDisabled from "svg/navigation-arrows/arrow-left-disabled.svg";
import arrowRightDisabled from "svg/navigation-arrows/arrow-right-disabled.svg";

import "./style.sass";

interface PaginationProps {
  page: number;
  totalPages: number;
  handlePagination: (page: number) => void;
}

const addZero = (page: number) => (page < 10 ? `0${page}` : page);

function Pagination({ handlePagination, page, totalPages }: PaginationProps) {
  return (
    <div className="pagination">
      <button
        className="pagination__nav"
        onClick={() => handlePagination(page - 1)}
        type="button"
        disabled={page === 1}
      >
        <img src={page === 1 ? arrowLeftDisabled : arrowLeft} alt="left" />
      </button>

      <PaginationPage handlePagination={() => handlePagination(1)} isActive={page === 1}>
        {addZero(1)}
      </PaginationPage>

      {page > 3 && <div className="pagination__page">...</div>}

      {page === totalPages && totalPages > 3 && (
        <PaginationPage handlePagination={() => handlePagination(page - 2)}>
          {addZero(page - 2)}
        </PaginationPage>
      )}
      {page > 2 && (
        <PaginationPage handlePagination={() => handlePagination(page - 1)}>
          {addZero(page - 1)}
        </PaginationPage>
      )}
      {page !== 1 && page !== totalPages && (
        <PaginationPage handlePagination={() => handlePagination(page)} isActive={!!page}>
          {addZero(page)}
        </PaginationPage>
      )}
      {page < totalPages - 1 && (
        <PaginationPage handlePagination={() => handlePagination(page + 1)}>
          {addZero(page + 1)}
        </PaginationPage>
      )}
      {page === 1 && totalPages > 3 && (
        <PaginationPage handlePagination={() => handlePagination(page + 2)}>
          {addZero(page + 2)}
        </PaginationPage>
      )}

      {page < totalPages - 2 && <div className="pagination__page">...</div>}

      <PaginationPage
        handlePagination={() => handlePagination(totalPages)}
        isActive={page === totalPages}
      >
        {addZero(totalPages)}
      </PaginationPage>

      <button
        className="pagination__nav"
        onClick={() => handlePagination(page + 1)}
        type="button"
        disabled={page === totalPages}
      >
        <img src={page === totalPages ? arrowRightDisabled : arrowRight} alt="right" />
      </button>
    </div>
  );
}

export default Pagination;
