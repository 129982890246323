import React from "react";
import { TCategory } from "../../../typings/event";

interface EventLabelProps {
  tag: TCategory;
}

const tags = {
  DEVELOPMENT: "РАЗРАБОТКА",
  SEO: "SEO",
  MARKETING: "МАРКЕТИНГ",
};

function EventLabel({ tag }: EventLabelProps) {
  return <>{tags[tag] && <div className="event__label">{tags[tag]}</div>}</>;
}

export default EventLabel;
