import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import VacancyContainer from "../../components/vacancy-container";
import CommandLeaderBanner from "../../components/command-leader-banner";
import FullWrapper from "../../wrappers/full-wrapper";
import BottomBanner from "../../components/bottom-banner";
import Page404 from "../error";


import './style.sass';

import commands from 'command.json';

const realDAta = commands[0].commands

//типа я получил данные с сервека


interface RouteParams {
    commandName: string
}

interface IState {
    data: any,
    commandName: string,
}

class CommandLeader extends Component<RouteComponentProps<RouteParams>, IState> {

    state = {
        data: {
            title: '',
            subtitle: '',
            img: '',
            list: []
        },
        commandName: "",
    }


    componentDidMount() {
        const realData = realDAta.find((item: any) => item.command === this.props.match.params.commandName)
        this.setState({
            commandName: this.props.match.params.commandName || "",
            data: realData
        })
    }

    render() {

        const {data} = this.state


        if (!data) return <Page404/>

        return (
            <section className="command-leader">

                <CommandLeaderBanner
                    title={data.title}
                    subtitle={data.subtitle}
                    imgLeader={data.img}
                    list={data.list}
                />


                <FullWrapper className="command-leader__container-vacancy" bigPaddings={true}>
                    <VacancyContainer
                        LinkToAll={false}
                        needTitle={false}
                        commandTitle={this.state.commandName}
                        join={false}
                        firstNeedNowVacancy={true}
                        needToggle={true}
                    />
                </FullWrapper>

                <BottomBanner/>


            </section>
        );
    }
}

export default CommandLeader;
