import React from "react";

import { useGetEvents, usePagination, useSort } from "./all-events.hooks";

import FullWrapper from "../../wrappers/full-wrapper";
import Join from "../../components/join";
import BottomBanner from "../../components/bottom-banner";
import BottomNavigation from "../../components/bottom-navigation";
import Event from "../../components/event";
import CommonTitle from "../../components/common-title";
import EventFilter from "../../components/event-filter";
import Pagination from "../../components/pagination";

import "./style.sass";

function AllEvents() {
  const _limit = 12;
  const { sortedBy, onSortHandler } = useSort();
  const { start, currentPage, onChangePageHandler, totalPages } = usePagination(sortedBy, _limit);
  const { events, isEmptyData, isError, isLoaded, errorMessage } = useGetEvents(
    sortedBy,
    start,
    _limit
  );

  return (
    <div className="all-events">
      <FullWrapper>
        <CommonTitle className="all-events__title">Все выступления</CommonTitle>
      </FullWrapper>
      <FullWrapper>
        <EventFilter onClick={onSortHandler} currentFilter={sortedBy} />
      </FullWrapper>
      <FullWrapper>
        <div className="all-events__wrapper">
          {!isLoaded && <div className="all-events__status">Загрузка...</div>}
          {isLoaded && events.map((event) => <Event key={event.id} grid {...event} />)}
          {isEmptyData && <div className="all-events__status">Здесь пока ничего нет.</div>}
          {isError && <div className="all-events__status">{errorMessage}</div>}
        </div>
        {isLoaded && totalPages > 1 && (
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            handlePagination={onChangePageHandler}
          />
        )}
      </FullWrapper>
      {/*<FullWrapper>*/}
      {/*  <div className="our-events__wrapper">*/}
      {/*    <div className="digital-learning__title digital-learning__title--xl">*/}
      {/*      Присоединяйтесь к команде*/}
      {/*    </div>*/}
      {/*    <div className="digital-learning__info digital-learning__info--indent-bottom-xl">*/}
      {/*      Мы ценим профессионалов и способствуем их росту.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</FullWrapper>*/}

      {/*<FullWrapper>*/}
      {/*  <Join />*/}
      {/*</FullWrapper>*/}

      <BottomBanner />
      <FullWrapper smallPaddings={true}>
        <BottomNavigation />
      </FullWrapper>
    </div>
  );
}

export default AllEvents;
