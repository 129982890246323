import React from "react";
import { IEvent, TCategory } from "../../../typings/event";
import clsx from "clsx";

import EventDate from "./EventDate";
import EventLabel from "./EventLabel";

interface EventCardProps
  extends Pick<
    IEvent,
    "category" | "completed" | "event_date" | "title" | "subtitle" | "img"
  > {
  organizeEvent?: boolean;
  grid?: boolean;
  disabled?: boolean;
  onToggleModalHandler?: () => void;
}

function EventCard({
  category,
  completed,
  event_date,
  organizeEvent,
  img,
  title,
  subtitle,
  grid,
  onToggleModalHandler,
}: EventCardProps) {
  return (
    <div onClick={onToggleModalHandler}>
      {!organizeEvent && (
        <EventDate
          start={event_date?.start}
          end={event_date?.end}
          completed={completed}
        />
      )}
      <div
        className={clsx("event__wrapper", {
          "event__wrapper--grid": grid,
        })}
      >
        <img
          className="event__img"
          src={`${process.env.REACT_APP_BACK_URL}${img?.url}`}
          alt={img?.name}
        />
      </div>
      <EventLabel tag={category as TCategory} />

      <div className="event__title">{title}</div>
	  {subtitle && <div className="event__subtitle">{subtitle}</div>}
    </div>
  );
}

export default EventCard;
