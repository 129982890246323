import React from 'react';
import CommonUsualText from "../common-usual-text";
import FullWrapper from "../../wrappers/full-wrapper";
import './style.sass';
import Gradient from "../gradient";


interface IProps {
    title?: any,
    subtitle?: any,
    imgLeader?: any,
    list?: Array<{ title?: string, subtitle?: string, quote?: boolean | string }>
}

const CommandLeaderBanner: React.FC<IProps> = ({title, subtitle, imgLeader, list}) => {

    return (
        <FullWrapper bigPaddings={true} isMargin={false} className="command-leader-banner ">
            <div className="command-leader-banner__wrapper padding-top-content">
                <div className="command-leader-banner__img">
                    <div className="command-leader-banner__photo" style={{backgroundImage: `url(${imgLeader})`}}/>
                </div>

                <div className="command-leader-banner__info">
                    <div className="command-leader-banner__title">{title}</div>
                    <CommonUsualText className="command-leader-banner__subtitle" myDangerouslySetInnerHTML={subtitle}/>

                    {
                        list ?
                            <div className="command-leader-banner__list-container">
                                {
                                    list.map((item, index) => (
                                        <div key={index} className="command-leader-banner__list-body">
                                            <CommonUsualText
                                                className="command-leader-banner__list-subtitle"
                                                isQuote={item.quote}
                                                myDangerouslySetInnerHTML={item.subtitle}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                            : ""
                    }


                </div>
            </div>
        </FullWrapper>
    );
};

export default CommandLeaderBanner;
