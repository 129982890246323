import React, {Component} from 'react';
import Slider from 'react-slick';
import './style.sass';
import CommonTitle from '../../components/common-title';
import Item from "./item";

const photoList = [
    {
        img: 'team-photo/n-1.jpg'
    },
    {
        img: 'team-photo/n-2.jpg'
    },
    {
        img: 'team-photo/n-3.jpg'
    },
    {
        img: 'team-photo/n-4.jpg'
    },
    {
        img: 'team-photo/n-5.jpg'
    },
    {
        img: 'team-photo/n-6.jpg'
    },
    {
        img: 'team-photo/n-7.jpg'
    },
    {
        img: 'team-photo/n-8.jpg'
    },
    {
        img: 'team-photo/t-9.jpg'
    },
    {
        img: 'team-photo/t-10.jpg'
    },
    {
        img: 'team-photo/t-11.jpg'
    },
    {
        img: 'team-photo/n-12.jpg'
    },
    {
        img: 'team-photo/n-13.jpg'
    },
    {
        img: 'team-photo/n-14.jpg'
    },
];

class TeamPhoto extends Component {
    render() {

        const settings = {
            dots: false,
            centerMode: true,
            centerPadding: '500px',
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        centerPadding: '450px',
                    }
                }, {
                    breakpoint: 1400,
                    settings: {
                        centerPadding: '350px',
                    }
                }, {
                    breakpoint: 1320,
                    settings: {
                        centerPadding: '300px',
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        centerPadding: '200px',
                    }
                }, {
                    breakpoint: 750,
                    settings: {
                        centerPadding: '150px',
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        centerPadding: '60px',
                    }
                }
            ]
        };


        return (
            <div className="team-photo" id="team-photo">
                <div className="full-wrapper">
                    <CommonTitle className="team-photo__title">Жизнь внутри</CommonTitle>
                    <div className="team-photo__text"></div>
                </div>

                <Slider className="team-photo__slider" {...settings}>
                    {photoList.map((item, index) => {
                        return (
                            <Item {...item} key={index}/>
                        );
                    })}
                </Slider>
            </div>
        );
    }
}

export default TeamPhoto;
