import {Component} from 'react';
import {withRouter} from 'react-router-dom';

class ScrollTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && !this.props.location.hash.length) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollTop)
