import React from 'react';
import Events from '../../components/events';
import Subscribe from "../../components/subscribe";

const Meetups = () => {
    return (
        <>
            <Events/>
            <Subscribe />
        </>
    )
}

export default Meetups;