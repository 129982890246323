import React, {useState} from "react";
import Modal from "../modal";

import './style.sass';

type TProps = {
    eventNew: boolean;
    eventData: any;
}

const EventCard = ({eventNew, eventData}: TProps) => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const {
        img,
        title,
        author,
        direction,
        date,
        time,
        video,
        presentation,
        info
    } = eventData;

    const openModal = () => {
        setOpen(true);
        setModalData(eventData);

        document.body.style.overflow = "hidden";
    };

    const closeModal = () => {
        setOpen(false);

        document.body.style.overflow = "";
    };

    const handleClick = () => {
        openModal();
    }

    return (
        <div className={eventNew ? "event-card" : "event-card event-card--new"}>
            <div className="event-card__block-img">
                <img className="event-card__img" src={img && process.env.REACT_APP_BACK_URL + img.url} alt=""/>
            </div>
            <div className="event-card__title">{title}</div>
            <div className="event-card__author">{author}</div>
            <div className="event-card__direction">{direction}</div>
            <div className="event-card__footer">
                {eventNew
                    ? <div
                        className="event-card__controls event-card__controls--new">
                        <div className="event-card__time-spending">
                            <span className="event-card__date">{date}</span>
                            <span
                                className="event-card__separator">I</span>
                            <span className="event-card__time">{time && time.slice(0, 5)}</span>
                        </div>
                        <button
                            className="event-card__btn button button--sm button--nomargin"
                            onClick={handleClick}
                        >
                            <span>Хочу на митап</span>
                        </button>
                    </div>
                    : <div
                        className="event-card__controls event-card__controls--old">
                        {video &&
                        <a
                            className="event-card__btn button button--sm button--nomargin button--transparent"
                            href={video}
                        >
                            <span>Смотреть видео</span>
                        </a>
                        }
                        {presentation &&
                        <a
                            className="event-card__btn button button--sm button--nomargin button--transparent"
                            onClick={handleClick}
                        >
                            <span>Получить презентацию</span>
                        </a>
                        }
                    </div>
                }
            </div>
            <Modal open={open} closeModal={closeModal} modalData={modalData} eventNew={eventNew}/>
        </div>
    )
}

export default EventCard;