import React from 'react';
import parse from "html-react-parser";

import FullWrapper from '../../wrappers/full-wrapper';
import MainTitle from '../../components/main-title';
import EducationSystem from '../../components/education-system';
import GradingSystem from '../../components/grading-system';
import Join from '../../components/join';
import BottomBanner from '../../components/bottom-banner';

import methodsIcon from 'svg/team/methods.svg'
import technologyIcon from 'svg/team/technology.svg'
import humanIcon from 'svg/team/human.svg'
import rocketIcon from 'svg/team/rocket.svg'
import supportIcon from 'svg/treasures__icon1.svg'
import personalGrowthIcon from 'svg/treasures__icon3.svg'

import './style.sass';

const benefits = [
	{
		title: 'гибкие методики <br /> разработки',
		icon: methodsIcon
	},
	{
		title: 'современный стек <br /> технологий',
		icon: technologyIcon
	},
	{
		title: 'крупные проекты в&nbsp;сфере финтеха, инноваций, B2B',
		icon: rocketIcon
	},
	{
		title: 'менторство и&nbsp;поддержка техлидов',
		icon: supportIcon
	},
	{
		title: 'среда для личностного и&nbsp;профессионального развития',
		icon: personalGrowthIcon
	},
	{
		title: 'спортивные традиции и&nbsp;корпоративные мероприятия',
		icon: humanIcon
	},
]

const digitalLearning = () => {
	return (
		<div className="digital-learning">
			<FullWrapper>
				<h1 className="digital-learning__main-title">
					Развитие в&nbsp;команде
				</h1>
			</FullWrapper>
			<FullWrapper>
			
			<div className='digital-learning__team-title'>
					&laquo;Вебпрактик&raquo;&nbsp;&mdash; это сильная и&nbsp;уверенная команда разработчиков,
					тестировщиков,
					дизайнеров и&nbsp;маркетологов.
				</div>
				
				<div className='digital-learning__team-subtitle'>
					Мы&nbsp;осваиваем новые фреймворки, следим за&nbsp;трендами диджитал-рынка и&nbsp;гордимся
					результатами
					нашей работы.
				</div>
				
				<div className='digital-learning__work'>
					<div className='digital-learning__work-title'>
						Работа в&nbsp;&laquo;Вебпрактик&raquo;&nbsp;&mdash; это
					</div>
					
					<div className='digital-learning__work-benefits'>
						<div>
							{benefits.slice(0, 3).map(benefit => (
								<div className='digital-learning__work-benefit' key={benefit.title}>
									<div className='digital-learning__work-benefit-icon'>
										<img className='digital-learning__work-benefit-img' src={benefit.icon}
											 alt='icon'/>
									</div>
									<div className='digital-learning__work-benefit-title'>
										{parse(benefit.title)}
									</div>
								</div>
							))}
						</div>
						<div>
							{benefits.slice(3).map(benefit => (
								<div className='digital-learning__work-benefit' key={benefit.title}>
									<div className='digital-learning__work-benefit-icon'>
										<img className='digital-learning__work-benefit-img' src={benefit.icon}
											 alt='icon'/>
									</div>
									<div className='digital-learning__work-benefit-title'>
										{parse(benefit.title)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				
				<EducationSystem/>
				
				<div className="digital-learning__title">Прозрачная система грейдов</div>
				<div className="digital-learning__info digital-learning__info--indent-bottom-md">
					Через личный кабинет можно посмотреть, на сколько вырастет уровень зарплаты при
					получении новой компетенции. По мере роста сотрудник получает не только прибавку
					к зарплате, но и более амбициозные рабочие задачи, участвует в работе над
					крупными федеральными проектами.
				</div>
				<GradingSystem/>
				
				{/*<div className="digital-learning__title digital-learning__title--xl">*/}
				{/*	Присоединяйтесь к команде*/}
				{/*</div>*/}
				{/*<div className="digital-learning__info digital-learning__info--indent-bottom-xl">*/}
				{/*	Мы ценим профессионалов и способствуем их росту.*/}
				{/*</div>*/}
				{/*<Join/>*/}
			</FullWrapper>
			<BottomBanner/>
		</div>
	
	);
};

export default digitalLearning;
