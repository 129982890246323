import React from "react";
import "./style.sass";

interface IProps {
  children?: any;
  className?: string;
  smallPaddings?: boolean;
  isMargin?: boolean;
  bigPaddings?: boolean;
  style?: object;
  disableLeftRightPadding?: boolean;
  id?: string;
}

const FullWrapper: React.FC<IProps> = ({
  children,
  style,
  className,
  smallPaddings,
  isMargin,
  bigPaddings,
  disableLeftRightPadding,
  id,
}) => {
  let isMarginStr = "";
  if (!isMargin) {
    isMarginStr = "case__item--is-marginless";
  }

  let isSmallPaddingsStr = "";
  if (smallPaddings && !bigPaddings) {
    isSmallPaddingsStr = "case__item--small-paddings";
  }

  let isBigPaddingsStr = "";
  if (bigPaddings && !smallPaddings) {
    isBigPaddingsStr = "case__item--big-paddings";
  }

  return (
    <div
      className={`case__item ${className} ${isSmallPaddingsStr} ${isMarginStr} ${isBigPaddingsStr}`}
      style={style}
      id={id}
    >
      {disableLeftRightPadding ? (
        <div
          className="full-wrapper"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {children}
        </div>
      ) : (
        <div className="full-wrapper">{children}</div>
      )}
    </div>
  );
};

FullWrapper.defaultProps = {
  children: "",
  className: "",
  smallPaddings: false,
  isMargin: false,
  bigPaddings: false,
  style: {},
  disableLeftRightPadding: false,
};

export default FullWrapper;
