import React from 'react';
import ReactMarkdown from 'react-markdown';

import Collapsible from 'react-collapsible';
import FullWrapper from "../../wrappers/full-wrapper";
import CommonTitle from "../common-title";

import './style.sass';

import listArrow from 'svg/faq__arrow.svg';

const Trigger: React.FC<{ title: any, img: any }> = ({title, img}) => {
    return (
        <div className="faq__trigger">
            <span className="faq__trigger-label">{title}</span>
            <img className="faq__trigger-img" src={img} alt=""/>
        </div>
    );
};

interface IProps {
    data?: any;
}

class Faq extends React.Component<IProps, {}> {

    render() {
        const faqData = this.props.data;

        return (
            <div className="faq">
                <FullWrapper bigPaddings={true}>
                    <div className="faq__wrapper">
                        <CommonTitle className="faq__title">{faqData.title}</CommonTitle>
                        <div className="faq__list">
                            {
                                faqData.faqList.map((item: any, index: number) => (
                                    <Collapsible key={index} trigger={<Trigger title={item.title} img={listArrow}/>} triggerTagName={'div'} classParentString="faq__item">
                                        <div className="faq__inner-list">
                                            <ReactMarkdown children={item.list}/>
                                        </div>
                                    </Collapsible>
                                ))
                            }
                        </div>
                    </div>
                </FullWrapper>
            </div>
        );
    }
};

export default Faq;
