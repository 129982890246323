import React, { Component } from "react";
import FullWrapper from "../wrappers/full-wrapper";

import vk from "svg/vk.svg";
import hhru from "svg/hhru.svg";
import youtube from "svg/youtube.svg";
import telegram from "svg/header/telegram.svg";
import github from "svg/header/github.svg";

import "./style.sass";

class Footer extends Component {
  render() {
    return (
      <FullWrapper className="footer">
        <div className="footer__wrapper">
          <div className="footer__rights">
            <div>
              &copy;&nbsp;{new Date().getFullYear()}. Вебпрактик.{" "}
              <span>Digital-агентство для проектов корпораций</span>
            </div>
            <div className="footer__doc">
              Раскрытие информации{" "}
              <a href="/files/СОУТ-2024.pdf" target="_blank">
                СОУТ&nbsp;2024
              </a>,{" "}
              <a href="/files/СОУТ-2025.pdf" target="_blank">
                СОУТ&nbsp;2025
              </a>
            </div>
            <div className="footer__doc">
              <a href="/files/WebpractikPolicy.pdf" target="_blank">
                Политика в отношении обработки персональных данных
              </a>
            </div>
          </div>
          <div className="footer__icons">
            <a
              target="_blank"
              href="https://t.me/webpractik_agency"
              rel="noopener noreferrer"
              className="footer__icon"
            >
              <img src={telegram} alt="telegram" />
            </a>
            <a
              target="_blank"
              href="https://vk.com/agencywebpractik"
              rel="noopener noreferrer"
              className="footer__icon"
            >
              <img src={vk} alt="vkontakte" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCpRYe-aT_kst84vU2XA5Y0Q"
              rel="noopener noreferrer"
              className="footer__icon"
            >
              <img src={youtube} alt="youtube" />
            </a>
            <a
              target="_blank"
              href="https://rostov.hh.ru/employer/726289"
              rel="noopener noreferrer"
              className="footer__icon"
            >
              <img src={hhru} alt="hh.ru" />
            </a>
            <a
              target="_blank"
              href="https://github.com/webpractik"
              rel="noopener noreferrer"
              className="footer__icon"
            >
              <img src={github} alt="github" />
            </a>
          </div>
        </div>
      </FullWrapper>
    );
  }
}

export default Footer;
