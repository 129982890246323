import React from "react";
import clsx from "clsx";

import { IEvent } from "../../../typings/event";
import useModal from "modules/hooks/useModal";

import EventModal from "./EventModal";
import EventCard from "./EventCard";

import "./style.sass";

interface EventProps extends IEvent {
  organizeEvent?: boolean;
  grid?: boolean;
}

function Event({
  img,
  title,
  subtitle,
  link,
  event_date,
  completed,
  organizeEvent = false,
  grid = false,
  video_link,
  category,
}: EventProps) {
  const { onToggleModalHandler, showModal } = useModal();

  return (
    <>
      {link && (
        <a
          className={clsx("event", {
            "event--grid": grid,
          })}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <EventCard
            category={category}
            completed={completed}
            event_date={event_date}
            title={title}
            subtitle={subtitle}
            img={img}
            organizeEvent={organizeEvent}
            grid={grid}
          />
        </a>
      )}
      {video_link && (
        <div
          className={clsx("event", {
            "event--grid": grid,
          })}
        >
          <EventCard
            category={category}
            completed={completed}
            event_date={event_date}
            title={title}
            subtitle={subtitle}
            img={img}
            organizeEvent={organizeEvent}
            grid={grid}
            onToggleModalHandler={onToggleModalHandler}
          />
          <EventModal
            show={showModal}
            onClose={onToggleModalHandler}
            title={title}
            link={video_link}
          />
        </div>
      )}
      {!video_link && !link && (
        <div
          className={clsx("event", {
            "event--grid": grid,
            "event--disabled": !video_link && !link,
          })}
        >
          <EventCard
            category={category}
            completed={completed}
            event_date={event_date}
            title={title}
            subtitle={subtitle}
            img={img}
            organizeEvent={organizeEvent}
            grid={grid}
          />
        </div>
      )}
    </>
  );
}

export default Event;
