import React, { Component } from "react";

import { LineProgress, LineProgressOptions } from "../line-progress";

import Slider from "react-slick";

import "./style.sass";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

enum Breackpoints {
  upperBigTablet = 4000,
  bigTablet = 1200,
  mobile = 750,
  smMobile = 575,
}

interface IProps {
  listSlider: any[];
  renderSliderItem: any;
  renderSliderPrevArrow?: any;
  renderSliderNextArrow?: any;
  classes: ClassesSlider;
  LineProgressOptions: LineProgressOptions;
  ResponsiveOptions: ResponsiveArray;
}

interface IState {
  width: number;
  slideIndex: number;
  progressBarLimit: number;
  percentProgressBar: number;
  currentSlide: number;
  lastSlide: number
}

interface ClassesSlider {
  arrowsContainerClass: string;
  sliderItemClass: string;
  sliderListClass: string;
  progressBarLineCLass: string;
  navigationsContainerClass: string;
}

// //ДОЛЖНО БЫТЬ ЧЕТЫРЕ ЭЛЕМЕНТА В МАССИВЕ
export interface ResponsiveArray extends Array<ResponsiveItem> {}

export interface ResponsiveItem {
  breakpoint: number;
  settings: {
    slidesToShow: number;
    slidesToScroll: number;
    arrows: boolean;
    [key: string]: any;
  };
}

export class SliderProgressBar extends Component<IProps, IState> {
  state = {
    width: 0,
    slideIndex: 1,
    progressBarLimit: 0,
    percentProgressBar: 0,
    currentSlide: 0,
    lastSlide: 0
  };

  mySlider: any;

  prev = () => this.mySlider.slickPrev();

  next = () => this.mySlider.slickNext();

  //узнаем предел для полоски загрузки слайдера
  getProgressBarLimit = (difference: number): void => {
    this.setState({
      progressBarLimit: this.props.listSlider.length + 1 - difference,
    });
  };

  //изменяем полоску загрузки злайдера
  setProgressBar = (): void => {
    this.setState({
      percentProgressBar:
        (this.state.slideIndex / this.state.progressBarLimit) * 100,
    });
  };

  //измеряем ресайз экрана
  updateDimensions = () => {
    // this.setState({width: window.innerWidth});
    if (window.innerWidth > Breackpoints.bigTablet) {
      this.getProgressBarLimit(
        this.props.ResponsiveOptions[0].settings.slidesToShow
      );
      this.setState({lastSlide: this.props.listSlider.length - 4})
    }
    if (
      window.innerWidth > Breackpoints.mobile &&
      window.innerWidth < Breackpoints.bigTablet
    ) {
      this.getProgressBarLimit(
        this.props.ResponsiveOptions[1].settings.slidesToShow
      );
      this.setState({lastSlide: this.props.listSlider.length - 3})
    }
    if (
      window.innerWidth > Breackpoints.smMobile &&
      window.innerWidth < Breackpoints.mobile
    ) {
      this.getProgressBarLimit(
        this.props.ResponsiveOptions[2].settings.slidesToShow
      );
      this.setState({lastSlide: this.props.listSlider.length - 2})
    }
    if (window.innerWidth < Breackpoints.smMobile) {
      this.getProgressBarLimit(
        this.props.ResponsiveOptions[3].settings.slidesToShow
      );
      this.setState({lastSlide: this.props.listSlider.length - 1})
    }
    this.setProgressBar();
  };

  componentDidMount() {
    Promise.resolve()
      .then(() => this.updateDimensions())
      .then(() => this.setProgressBar())
      .then(() => window.addEventListener("resize", this.updateDimensions));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const settings = {
      infinite: false,
      arrow: false,
      dots: false,
      speed: 500,
      slidesToShow: this.props.ResponsiveOptions[0].settings.slidesToShow,
      slidesToScroll: 1,
      responsive: this.props.ResponsiveOptions,
      //сликовская функция для вычисления номера текущего айтема
      beforeChange: (current: number, next: number) => {
        Promise.resolve()
          .then(() => this.setState({ slideIndex: ++next }))
          .then(() => this.setState({ currentSlide: next - 1 }))
          .then(() => this.setProgressBar());
      },
    };

    return (
      <div className="slider-progress-bar">
        <div className={this.props.classes.sliderListClass}>
          <Slider
            ref={(something) => (this.mySlider = something)}
            {...settings}
          >
            {this.props.listSlider.map((item, key) => (
              <div className={this.props.classes.sliderItemClass} key={key}>
                {this.props.renderSliderItem({ ...item, index: key })}
              </div>
            ))}
          </Slider>
          {this.props.listSlider.length > 4 && (
            <div className={this.props.classes.navigationsContainerClass}>
              <div className={this.props.classes.arrowsContainerClass || ""}>
                {this.props.renderSliderPrevArrow(
                  this.prev,
                    this.state.lastSlide,
                  this.state.currentSlide
                )}

                {this.props.renderSliderNextArrow(
                  this.next,
                  this.state.lastSlide,
                  this.state.currentSlide
                )}
              </div>

              <LineProgress
                strokeColor={"rgba(121, 39, 224, 1)"}
                className={this.props.LineProgressOptions.className}
                percent={this.state.percentProgressBar}
                strokeWidth={this.props.LineProgressOptions.strokeWidth}
                trailWidth={this.props.LineProgressOptions.trailWidth}
                trailColor={this.props.LineProgressOptions.trailColor}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
