import React, {Component} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import ErrorInput from "../error-input";
import imgAttach from 'svg/attach.svg';
import iconFile from 'svg/file 2.svg';
import axios from 'axios';

import './style.sass';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'слишком короткое имя')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите имя"),
    email: Yup.string()
        .email('введите корректный email')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите email")
})


interface Values {
    name: string | null,
    email: string | null,
    words: string | null,
}

interface IState {
    isSuccess: boolean
}

class PresentationForm extends Component<{}, IState> {
    state = {
        isSuccess: false
    }

    activaseIsSuccess = () => {
        this.setState({
            isSuccess: true
        })
    }

    mySubmit = async (values: Values, actions: FormikHelpers<Values>) => {

        let totalData = {
            fields: {
                "TITLE": values.name,
                "EMAIL": values.email,
                "WORDS": values.words,
                "OPENED": "Y",
                "CATEGORY_ID": 12,
                "ASSIGNED_BY_ID": 1818,
                "PROBABILITY": 0,
                "CURRENCY_ID": "RUB",
                "OPPORTUNITY": 0,
                "BEGINDATA": new Date(),
                "DATA_CREATE": new Date(),
                "UF_CRM_58F5E45E004FF": document.cookie,
                "UF_CRM_1505909277": values.name,
                "UF_CRM_1505909324": values.email,
                "UF_CRM_1505909401": values.words,
                "UF_CRM_1505909470": window.location.href
            }
        };

        actions.setSubmitting(true)

        axios
            // .post("https://cp.webpractik.ru/rest/1/58pkir8vmteeyte8/crm.deal.add.json", totalData)
            .post(`${process.env.REACT_APP_BACK_URL + '/meetups'}`, totalData)
            .then(() => actions.resetForm())
            .then(() => actions.setSubmitting(false))
            .then(() => this.activaseIsSuccess());
    }

    render() {
        return (
            <div className="presentation-form__wrapper">
                <div className={`presentation-form__success ${this.state.isSuccess ? 'presentation-form__success--active' : ''}`}>Ваше сообщение успешно отправлено</div>

                <Formik validationSchema={validationSchema} initialValues={{name: "", email: "", words: ""}}
                        onSubmit={(values, actions) => {
                            this.mySubmit(values, actions as FormikHelpers<Values>)
                        }}
                >
                    {({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
                        <Form className="presentation-form">
                            <div className="presentation-form__title">
                                Получить презентацию
                            </div>
                            <div className="presentation-form__inner-wrapper">
                                <div className="presentation-form__item">
                                    <label className="presentation-form__input-container">
                                        <span className="input__name">Имя</span>
                                        <input
                                            name="name"
                                            id="feedbackform__name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            type="text"
                                            className={`input ${touched.name && errors.name
                                                ? "input__error" : ""}`
                                            }
                                        />
                                        {
                                            errors.name && <ErrorInput touched={touched.name} message={errors.name}/>
                                        }
                                    </label>

                                    <label className="presentation-form__input-container">
                                        <span className="input__name">E-mail</span>
                                        <input
                                            name="email"
                                            id="feedbackform__email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            type="email"
                                            className={`input ${touched.email && errors.email ? "input__error" : ""}`}
                                        />
                                        {
                                            errors.email && <ErrorInput touched={touched.email} message={errors.email}/>
                                        }
                                    </label>

                                </div>
                                <div className="presentation-form__item">
                                    <label htmlFor="words">
                                        <div className="input__name">Какие события были бы вам интересны</div>
                                    </label>
                                    <textarea
                                        className="presentation-form__form-textarea"
                                        name="words"
                                        value={values.words}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="presentation-form__bottom">
                                <button className="button presentation-form__submit" disabled={isSubmitting} type="submit"><span>отправить</span></button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}


export default PresentationForm;
