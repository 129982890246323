import { useCallback, useEffect, useMemo, useState } from "react";

import { TFilter, IEvent } from "../../../typings/event";

import useFetchDataFromStrapi from "../../hooks/useFetchDataFromStrapi";

export function usePagination(sortedBy: TFilter, _limit: number) {
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);

  const configToTotal = useMemo(() => {
    if (sortedBy !== "ALL") {
      return {
        params: {
          category: sortedBy,
        },
      };
    }
  }, [sortedBy]);

  const { data } = useFetchDataFromStrapi<number>(
    "events/count",
    0,
    configToTotal
  );

  const totalPages = Math.ceil(data / _limit);

  const onChangePageHandler = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      if (newPage !== 1) {
        setStart((newPage - 1) * _limit);
      } else {
        setStart(0);
      }

      window.scrollTo({
        top: 0,
      });
    },
    [_limit]
  );

  useEffect(() => {
    setCurrentPage(1);
    setStart(0);
  }, [sortedBy]);

  return {
    currentPage,
    start,
    onChangePageHandler,
    totalPages,
  };
}

export function useSort() {
  const [sortedBy, setSortedBy] = useState<TFilter>("ALL");
  const onSortHandler = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { type } = ev.currentTarget.dataset;

      setSortedBy(type as TFilter);
    },
    []
  );

  return { sortedBy, onSortHandler };
}

export function useGetEvents(
  sortedBy: TFilter,
  start: number = 0,
  _limit: number
) {
  const configToData = useMemo(() => {
    if (sortedBy !== "ALL") {
      return {
        params: {
          _sort: "completed:asc,published_at:desc",
          category: sortedBy,
          _start: start,
          _limit,
        },
      };
    } else {
      return {
        params: {
          _sort: "completed:asc,published_at:desc",
          _start: start,
          _limit,
        },
      };
    }
  }, [_limit, sortedBy, start]);

  const {
    data: events,
    error: errorMessage,
    isLoaded,
  } = useFetchDataFromStrapi<IEvent[]>("events", [], configToData);

  useEffect(() => {
    if (events.length) {
      events.sort((a, b) => {
        if (
          !!a.event_date?.start &&
          !!b.event_date?.start &&
          !(a.completed || b.completed)
        ) {
          return (
            +new Date(a.event_date?.start) - +new Date(b.event_date?.start)
          );
        } else {
          return 0;
        }
      });
    }
  }, [events]);

  const isEmptyData = isLoaded && !events.length && !errorMessage;
  const isError = errorMessage && isLoaded && !isEmptyData;

  return {
    events,
    isEmptyData,
    isError,
    isLoaded,
    errorMessage,
  };
}
