import React from 'react';

import './style.sass';
import FullWrapper from '../../wrappers/full-wrapper';

const History = () => {
    return (
        <FullWrapper className="goal" isMargin={false}>
            <div className="goal__wrapper" id="goal">
                <div className="goal__container">
                    <div className="goal__content">
                        <div className="goal__img">
                            <img src="../goal.png" alt="img" />
                        </div>
                        <div className="goal__text">
                            <div className="goal__title">Цель</div>
                            <div className="goal__subTitle">
                                Стать лучшим диджитал-агентством&nbsp;страны для корпораций
                            </div>
                            <div className="goal__description">
                                Мы&nbsp;создаем удобные и&nbsp;надежные цифровые сервисы для банков,
                                страховых компаний, в&nbsp;сфере ЖКХ и&nbsp;строительства,
                                здравоохранения и&nbsp;промышленности, которые становятся
                                неотъемлемой частью жизни миллионов граждан.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FullWrapper>
    );
};

export default History;
