import { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

function useFetchDataFromStrapi<T>(route: string, initialState: T, config?: AxiosRequestConfig) {
  const [data, setData] = useState<T>(initialState);
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);

    axios
      .get<any, { data: T }>(`${process.env.REACT_APP_BACK_URL}/${route}`, config)
      .then(({ data }) => setData(data))
      .catch((err) => {
        if (err instanceof Error) {
          setError("Ой-ой, что-то пошло не так!");
        }
      })
      .finally(() => setIsLoaded(true));
  }, [config, route]);

  return {
    data,
    error,
    isLoaded,
  };
}

export default useFetchDataFromStrapi;
