import React from "react";

import "./styles.sass";

const WebpractikTeam = () => {
  return (
    <>
      <div className="webpractik-team">
        <div className="webpractik-team__title">
          Но самая главная ценность — это{" "}
          <span style={{ color: "#7927E0" }}>наша команда</span>, которая
          профессионально выросла вместе с агентством.
        </div>

        <div className="webpractik-team__container">
          <div className="webpractik-team__info">
            120+
            <span className="webpractik-team__command">сотрудников</span>
          </div>

          <div className="webpractik-team__list">
            <div className="webpractik-team__target">
              12 лет
              <span className="webpractik-team__desc">на рынке ИТ-услуг</span>
            </div>
            <div className="webpractik-team__target">
              3<span className="webpractik-team__desc">бизнес-юнита</span>
            </div>
            <div className="webpractik-team__target">
              60 +
              <span className="webpractik-team__desc">
                разработчиков в команде
              </span>
            </div>
            <div className="webpractik-team__target">
              4 года
              <span className="webpractik-team__desc">
                средний стаж работы в агентстве
              </span>
            </div>
          </div>
        </div>

        <div className="webpractik-team__subtitle">
          Все сотрудники нашего агентства работают на постоянной основе и
          находятся в одном офисе в центре города Ростова-на-Дону. Здесь
          сосредоточены наши ресурсы, также есть представительство в Москве.
        </div>
      </div>
    </>
  );
};

export default WebpractikTeam;
