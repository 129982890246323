import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.sass";
import CommonTitle from "../common-title";
import VacancyItem from "../vacancy-item";
import svgCat from "../../../svg/vacancy/cat.svg";
import axios from "axios";

const VacanciesNot = () => {
  return <div className="vacancy__not">Ой, здесь пока ничего нет. Просто напишите&nbsp;HR</div>;
};

type TVacancies = {
  [key: string]: any;
};

interface IState {
  nowNeed: Object[]; //список активных вакансий
  activeExactlyNowNeed: boolean; //прямо сейчас показывать активные вакансии
  activeList: Object[]; //список активных вакансий
  passiveList: Object[]; //список пассивных вакансий
  allList: Object[]; //список вакансий котоыре отображаются в данный момент
  fake_db: TVacancies;
  error: null;
  loaded: boolean;
}

interface IProps {
  needToggle: boolean; //нужна ли тогл переключалка
  firstNeedNowVacancy?: boolean; //перво-наперво какой список вакансий выводить
  join: boolean; //нужны ли нам кнопки роуты на вакансиии данной команды
  commandTitle: string; //вывести список вакансий определенной команды
  needTitle: boolean; //нужен ли заголовок КОМАНДЕ НУЖНЫ
  LinkToAll: boolean; //нужна ли кнопка на страничку всех вакансий
}

class VacancyContainer extends Component<IProps, IState> {
  state = {
    nowNeed: [],
    activeExactlyNowNeed: this.props.firstNeedNowVacancy || true,
    activeList: [],
    passiveList: [],
    allList: [],
    fake_db: [],
    error: null,
    loaded: false,
  };

  // получить список всех вакансий или конкретной команды
  getAllOrCommandList = async () => {
    if (this.props.commandTitle === "all") {
      this.setState({ allList: this.state.fake_db });
    } else {
      let sorted: Array<object> = [];
      Promise.resolve()
        .then(() => this.setState({ allList: this.state.fake_db }))
        .then(
          () =>
            (sorted = this.state.allList.filter(
              (item: { [key: string]: string | boolean }) =>
                item.command === this.props.commandTitle
            ))
        )
        .then(() => this.setState({ allList: sorted }));
    }
  };

  // получить список активных и пассивных вакансий
  getActiveAndPassiveVacancies = () => {
    let activeArr: Array<object> = [];
    let passiveArr: Array<object> = [];
    Promise.resolve()
      .then(
        () =>
          (activeArr = this.state.allList.filter(
            (item: { [key: string]: string | boolean }) => item.need === true
          ))
      )
      .then(
        () =>
          (passiveArr = this.state.allList.filter(
            (item: { [key: string]: string | boolean }) => item.need === false
          ))
      )
      .then(() =>
        this.setState({
          activeList: activeArr,
          passiveList: passiveArr,
        })
      );
  };

  //узнать , есть ли активные вакансии, если нет - по умолчанию показать пассивные
  isExistActive = () => {
    let activeArr: Array<object> = this.state.allList.filter(
      (item: { [key: string]: string | boolean }) => item.need === true
    );
    if (activeArr.length === 0) {
      this.setState({ activeExactlyNowNeed: false });
    } else {
    }
  };

  // переключить список активных или пассивных вакансий
  switchList = () =>
    this.setState((prevState) => ({
      activeExactlyNowNeed: !prevState.activeExactlyNowNeed,
    }));

  componentDidMount = async () => {
    try {
      const response = await axios.get(`https://back.team.webpractik.ru/vacancies`);
      // const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/vacancies`);

      this.setState({ fake_db: response.data, loaded: true });
    } catch (error) {
      this.setState({ error });
    }

    Promise.resolve()
      .then(() => this.getAllOrCommandList())
      .then(() => this.getActiveAndPassiveVacancies())
      .then(() => this.isExistActive())
      .finally(() => this.setState({ loaded: true }));
  };

  render() {
    const loaded = this.state.loaded;

    if (!loaded) return <></>;

    return (
      <div className="vacancy__wrapper">
        {this.props.needTitle ? (
          <CommonTitle className="vacancy__title">Команде нужны</CommonTitle>
        ) : (
          ""
        )}

        {this.props.needToggle ? (
          <div className="vacancy__toggle-body-wrapper">
            <div className="vacancy__toggle-body">
              <div
                className={`vacancy__toggle-switch
                                    ${
                                      this.state.activeExactlyNowNeed
                                        ? ""
                                        : "vacancy__toggle-switch--right"
                                    }`}
                onClick={this.switchList}
              ></div>
              <div
                className={`vacancy__toggle-title vacancy__toggle-title--right
                                    ${
                                      this.state.activeExactlyNowNeed
                                        ? "vacancy__toggle-title--active"
                                        : ""
                                    }
                                    `}
              >
                ищем прямо сейчас
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="vacancy__list">
          {this.state.activeExactlyNowNeed ? (
            this.state.activeList.length > 0 ? (
              this.state.activeList.map(({ title, text, user, type, command, id }, index) => {
                return (
                  <VacancyItem
                    key={id}
                    id={id}
                    title={title}
                    subtitle={text}
                    user={user}
                    command={command}
                    link={type}
                    join={this.props.join}
                    index={index}
                  />
                );
              })
            ) : (
              <VacanciesNot />
            )
          ) : this.state.passiveList.length > 0 ? (
            this.state.passiveList.map(({ title, text, user, type, command, id }, index) => {
              return (
                <VacancyItem
                  key={id}
                  id={id}
                  title={title}
                  subtitle={text}
                  user={user}
                  command={command}
                  link={type}
                  join={this.props.join}
                  index={index}
                />
              );
            })
          ) : (
            <VacanciesNot />
          )}
        </div>
        <div>
          {this.props.LinkToAll ? (
            <div className="vacancy__link-all">
              <div className="vacancy__link-all-icon">
                <img src={svgCat} alt="иконка котика" />
              </div>
              <div>
                <div className="vacancy__link-all-title">Кто еще бывает нужен</div>
                <div className="vacancy__link-all-subtitle">
                  Здесь можно посмотреть все возможные вакансии и&nbsp;отправить резюме
                  на&nbsp;позицию, которая пока закрыта. Когда откроется, мы&nbsp;свяжемся
                  с&nbsp;вами.
                </div>
              </div>

              <Link to={"/allvacancies/"} className={`vacancy-item__all-btn-body`}>
                <span className="vacancy-item__all-btn-label">посмотреть все позиции</span>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default VacancyContainer;
