import React from 'react';

import './style.sass'

const EducationSystem = () => {
	return (
		<div className="education-system">
			<div className="education-system__preview">
				<div
					className="education-system__paragraph education-system__paragraph--xl education-system__paragraph--indent-bottom">
					У&nbsp;нас разработана одна из&nbsp;лучших в&nbsp;стране система грейдов. Она позволяет каждому
					новому члену команды планомерно, в&nbsp;комфортном для себя темпе повышать свой уровень, получая
					новые компетенции.
				</div>
			</div>
			<div className='education-system__wrapper'>
				<div className="education-system__paragraph">
					У&nbsp;нас нет обязательных домашних заданий и&nbsp;строгих дедлайнов: каждый сам решает,
					с&nbsp;какой скоростью прокачивать свои скилы, и&nbsp;планирует карту развития по&nbsp;календарю.
					Прогресс по&nbsp;каждой компетенции и&nbsp;текущий ранг можно отслеживать в&nbsp;личном кабинете.
				</div>
				<div className="education-system__info">
					<div className="education-system__paragraph">
						За&nbsp;учеником закреплен наставник, который проверяет задания и&nbsp;помогает разобраться
						со&nbsp;сложными темами.
					</div>
				</div>
			</div>
		</div>
	)
}

export default EducationSystem;