export const historyData = [
  {
    id: "1",
    year: "2011",
    // title: `<span>Основание агентства</span>
    //         <span>Старт ежегодных конференций по интернет-маркетингу «ТРИМ»</span>
    //         <span>Сертификация Яндекс</span>
    //         <span>Приход ключевых сотрудников: Анастасия Андронова,
    //         CMO маркетинг, Иван Поддубный, CTO</span>`,
    title: "Основание агентства",
    subtitle: "Старт ежегодных конференций по интернет-маркетингу «ТРИМ»",
    text: "Сертификация Яндекс",
    content:
      "Приход ключевых сотрудников: Анастасия Андронова, CMO маркетинг, Иван Поддубный, CTO",
    subcontent: "",
    subtext: "",
  },
  {
    id: "2",
    year: "2012",
    // title: `<span>Операционное развитие агентства</span>
    //         <span>Рост клиентской базы</span>
    //         <span>Разработка первого корпоративного сайта на платформе Bitrix</span>`,
    title: "Операционное развитие агентства",
    subtitle: "Рост клиентской базы",
    text: "Разработка первого корпоративного сайта на платформе Bitrix",
    content: "",
    subcontent: "",
    subtext: "",
  },
  {
    id: "3",
    year: "2013",
    // title: `<span>Выделение в структуре фронт- и бэк-офиса, отделов SEO, рекламы, менеджеров проектов</span>
    //         <span>Партнерство с компанией «Ровен»</span>`,
    title:
      "Выделение в структуре фронт- и бэк-офиса, отделов SEO, рекламы, менеджеров проектов",
    subtitle: "Партнерство с компанией «Ровен»",
    text: "",
    content: "",
    subcontent: "",
    subtext: "",
  },
  {
    id: "4",
    year: "2014",
    // title: `<span>Основание Южной школы интернет-бизнеса</span>
    //         <span>Сертификация Google IQ</span>
    //         <span>50+ региональных корпоративных клиентов</span>`,
    title: "Основание Южной школы интернет-бизнеса",
    subtitle: "Сертификация Google IQ",
    text: "50+ региональных корпоративных клиентов",
    content: "",
    subcontent: "",
    subtext: "",
  },
  {
    id: "5",
    year: "2015",
    // title: `<span>Нас 25 человек</span>
    //         <span>Сертификация Google Partners</span>
    //         <span>Первый федеральный клиент — «Российская венчурная компания»</span>`,
    title: "Нас 25 человек",
    subtitle: "Сертификация Google Partners",
    text: "Первый федеральный клиент — «Российская венчурная компания»",
    content: "",
    subcontent: "",
    subtext: "",
  },
  {
    id: "6",
    year: "2016",
    // title: `<span>Золотой сертифицированный партнер «1C-Битрикс»</span>
    //         <span>1 место в «Рейтинге Рунета» среди SEO-компаний ЮФО</span>
    //         <span>Старт работы с таргетированной рекламой в соцсетях и ведения сообществ</span>
    //         <span>Партнерство с брендами «Темпавто», VIRBACauto, «Додо-пицца»</span>`,
    title: "Золотой сертифицированный партнер «1C-Битрикс»",
    subtitle: "1 место в «Рейтинге Рунета» среди SEO-компаний ЮФО",
    text:
      "Старт работы с таргетированной рекламой в соцсетях и ведения сообществ",
    content: "Партнерство с брендами «Темпавто», VIRBACauto, «Додо-пицца»",
    subcontent: "",
    subtext: "",
  },
  {
    id: "7",
    year: "2017",
    // title: `<span>Начало работы со сквозной аналитикой и персонализированным маркетингом</span>
    //         <span>Создание выделенного внутреннего DevOps-отдела на смену аутсорсу</span>
    //         <span>Первые проекты на react</span>
    //         <span>Партнерство с «ЛУКОЙЛ», «КПИ», BONUM</span>`,
    title:
      "Начало работы со сквозной аналитикой и персонализированным маркетингом",
    subtitle:
      "Создание выделенного внутреннего DevOps-отдела на смену аутсорсу",
    text: "Первые проекты на react",
    content: "Партнерство с «ЛУКОЙЛ», «КПИ», BONUM",
    subcontent: "",
    subtext: "",
  },
  {
    id: "8",
    year: "2018",
    // title: `<span>Юниты «Разработка» и «Маркетинг» формируются как самостоятельные структуры</span>
    //         <span>Запуск собственного сервиса — конвертор файлов onlineconvertfree.com</span>
    //         <span>Партнерство с «Российским экспортным центром», «Мособлгаз»</span>
    //         <span>Международный проект для «Л'этуаль</span>`,
    title:
      "Юниты «Разработка» и «Маркетинг» формируются как самостоятельные структуры",
    subtitle:
      "Запуск собственного сервиса — конвертор файлов onlineconvertfree.com",
    text: "Партнерство с «Российским экспортным центром», «Мособлгаз»",
    content: "Международный проект для «Л'этуаль»",
    subcontent: "",
    subtext: "",
  },
  {
    id: "10",
    year: "2019",
    // title: `<span>Нас 60 человек</span>
    //         <span>Формирование команд для работы с крупными проектами</span>
    //         <span>Получение уверенной экспертизы по Kubernetes</span>
    //         <span>Разработка сложных сервисов для госструктур и В2В-клиентов: Фонд ЖКХ,
    //         «Корпорация развития Дальнего Востока и Арктики», «Сколково», «Роснано»,
    //         «Роскосмос», «Ростелеком», «МТС-Инвестиции», Яндекс.Касса</span>
    //         <span>Развитие международного проекта Bloomex</span>`,
    title: "Нас 60 человек",
    subtitle: "Формирование команд для работы с крупными проектами",
    text: "Получение уверенной экспертизы по Kubernetes",
    content:
      "Разработка сложных сервисов для госструктур и В2В-клиентов: Фонд ЖКХ, «Корпорация развития Дальнего Востока и Арктики», «Сколково», «Роснано», «Роскосмос», «Ростелеком», «МТС-Инвестиции», Яндекс.Касса",
    subcontent: "Развитие международного проекта Bloomex",
    subtext: "",
  },
  {
    id: "11",
    year: "2020",
    // title: `<span>Внедрена собственная система грейдов с понятной карьерной лестницей</span>
    //         <span>Разработка высоконагруженных систем для «Почта-Банк», конкурса «ПРО/ЧТЕНИЕ»</span>
    //         <span>Награды Red Dot, «Золотой сайт», «Событие года», «Серебряный Лучник» за разработку платформы для международного киберфестиваля Rukami</span>
    //         <span>Сформирован отдел контента</span>`,
    title:
      "Внедрена собственная система грейдов с понятной карьерной лестницей",
    subtitle:
      "Разработка высоконагруженных систем для «Почта-Банк», конкурса «ПРО/ЧТЕНИЕ»",
    text:
      "Награды Red Dot, «Золотой сайт», «Событие года», «Серебряный Лучник» за разработку платформы для международного киберфестиваля Rukami",
    content: "Сформирован отдел контента",
    subcontent: "",
    subtext: "",
  },
  {
    id: "12",
    year: "2021",
    // title: `<span>> 80% проектов на react </span>
    //         <span>1 место в «Рейтинге Рунета» среди агентств России по разработке и продвижению сайтов в премиум-сегменте</span>
    //         <span>Запуск собственного проекта Linkbuilder.ai</span>
    //         <span>Партнерства с Агентством стратегических инициатив по нескольким ключевым проектам</span>
    //         <span>Интегратор ИT-решений для крупного бизнеса Bitrix Enterprise</span>`,
    title: "> 80% проектов на react ",
    subtitle:
      "1 место в «Рейтинге Рунета» среди агентств России по разработке и продвижению сайтов в премиум-сегменте",
    text: "Запуск собственного проекта Linkbuilder.ai",
    content:
      "Партнерства с Агентством стратегических инициатив по нескольким ключевым проектам",
    subcontent: "Интегратор ИT-решений для крупного бизнеса Bitrix Enterprise",
    subtext: "",
  },
  {
    id: "13",
    year: "2022",
    // title: `<span>>100+ человек в команде</span>
    //         <span>1 место в «Рейтинге Рунета» среди агентств России интернет-маркетинга / Верхний ценовой сегмент</span>
    //         <span>6 место в «Рейтинге Рунета» по созданию и продвижению сайтов</span>
    //         <span>35 место в «Национальном рейтинге коммуникационных компаний»</span>
    //         <span>39 место среди агентств полного цикла в России (Ruward)</span>
    //         <span>В клиентском портфеле более 150 крупных региональных производителей B2B-сегмента, федеральных брендов</span>`,
    title: "100+ человек в команде",
    subtitle:
      "1 место в «Рейтинге Рунета» среди агентств России интернет-маркетинга / Верхний ценовой сегмент",
    text: "6 место в «Рейтинге Рунета» по созданию и продвижению сайтов",
    content: "35 место в «Национальном рейтинге коммуникационных компаний»",
    subcontent: "39 место среди агентств полного цикла в России (Ruward)",
    subtext:
      "В клиентском портфеле более 150 крупных региональных производителей B2B-сегмента, федеральных брендов",
    description: "Создали новое комьюнити rndSEO",
    subdescription: "Сооснователь фонда «Развитие ИТ-образования»",
  },
  {
    id: "14",
    year: "2023",
    title:
      "1 место среди премиум-агентств интернет-маркетинга в «Рейтинге Рунета»",
    subtitle: "1 место в «Рейтинге Рунета» по ведению аккаунта в соцсетях",
    text:
      "2 место  по созданию и продвижению сайтов для корпораций в «Рейтинге Рунета»",
    content:
      "Разработка сложных сервисов и развитие веб-проектов для «Газпромнефть», «Росгосстрах», Российского экспортного центра, Фонда развития территорий",
    subcontent:
      "Победа в Workspace Digital Awards-2023 в категории лучшие сайты для финансовой сферы",
    subtext:
      "Новые партнеры по SEO: «Промсвязьбанк», «Ренессанс Банк», «Открытие, «ОТП Банк», «Центр-инвест»",
    description: "30 место в «Национальном рейтинге коммуникационных компаний»",
    subdescription:
      "Собственный продукт агентства облачный конвертер файлов включен в реестр российского ПО",
  },
];
