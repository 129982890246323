import {useEffect, useMemo} from "react";

import {IDevelopmentEvent, IEvent, ILeisure} from "../../../typings/event";

import useFetchDataFromStrapi from "../../hooks/useFetchDataFromStrapi";

const useGetEventsData = () => {
	const performancesConfig = useMemo(
		() => ({
			params: {
				_sort: "completed:asc,published_at:desc",
				_limit: 7,
			},
		}),
		[]
	);
	
	const developmentsConfig = useMemo(
		() => ({
			params: {
				_sort: "published_at:desc",
			},
		}),
		[]
	);
	
	const leisureConfig = useMemo(
		() => ({
			params: {
				_sort: "published_at:desc",
			},
		}),
		[]
	);
	
	const {data: performances} = useFetchDataFromStrapi<IEvent[]>(
		"events",
		[],
		performancesConfig
	);
	
	const {data: developments} = useFetchDataFromStrapi<IDevelopmentEvent[]>(
		"developments",
		[],
		developmentsConfig
	);
	
	const {data: leisure} = useFetchDataFromStrapi<ILeisure[]>(
		"leisures",
		[],
		leisureConfig
	);
	
	useEffect(() => {
		if (performances.length) {
			performances.sort((a, b) => {
				if (
					!!a.event_date?.start &&
					!!b.event_date?.start &&
					!(a.completed || b.completed)
				) {
					return (
						+new Date(a.event_date?.start) - +new Date(b.event_date?.start)
					);
				} else {
					return 0;
				}
			});
		}
	}, [performances]);
	
	return {
		performances,
		leisure,
		developments,
	};
};

export default useGetEventsData;
