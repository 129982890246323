import React, {useState} from 'react';
import {useResize} from "../../hooks/useResize";

export interface IGradingSystemItem {
    name: string;
    info?: string;
}

const GradingSystemItem: React.FC<IGradingSystemItem>  = ({name, info}) => {
    const [open, setOpen] = useState(false);

    const width = useResize();
    const isMobile = width <= 992 && typeof window !== 'undefined';

    const handleOpenInfo = () => {
        if (!isMobile) return;

        setOpen(!open);
    }


    return (
        <div className="grading-system__item">
            <div className="grading-system__dot" onClick={handleOpenInfo}/>
            <div className={`grading-system__item-info ${open ? 'grading-system__item-info--open' : ''}`}>
                <div className="grading-system__name">{name}</div>
                {
                    info &&
                    <div className="grading-system__info">
                        {info}
                    </div>
                }
            </div>
        </div>
    )
}

export default GradingSystemItem;