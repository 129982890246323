import React from 'react';
import './style.sass';
import {Link} from 'react-router-dom';

import imgBg from 'svg/404-errors__img.svg';

const dataError404 = {
    number: '404',
    title: 'Страница не найдена :('
}


const Page404 = () => (
    <section className="errors">
        <div className="full-wrapper">
            <div className="errors__wrapper" style={{backgroundImage: `url(${imgBg})`}}>
                <div className="errors__number">{dataError404.number}</div>
                <h1 className="errors__title">{dataError404.title}</h1>
                <div className="errors__text">
                    Вы можете вернуться на <Link to="/">главную страницу</Link>
                </div>
            </div>
        </div>
    </section>
);

export default Page404;
