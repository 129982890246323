import React, { Component } from "react";
import MainWindow from "../../components/main-window";
import Mission from "../../components-layouts/mission";
import Leaders from "../../components-layouts/leaders";
import Treasures from "../../components-layouts/treasures";
import FullWrapper from "../../wrappers/full-wrapper";
import VacancyShortContainer from "../../components/vacancy-short-container";
import BottomBanner from "../../components/bottom-banner";
import bg from "pic/bg.jpg";

import "./style.sass";
import TeamPhoto from "../../components-layouts/team-photo";
import History from "../../components-layouts/history";
import Goal from "../../components-layouts/goal";

class MainPage extends Component {
  render() {
    return (
      <>
        <MainWindow
          bgImage={bg}
          title={"Улучшаем жизнь людей, развивая цифровые сервисы страны"}
        />
        <History />
        {/*<Mission/>*/}
        <Goal />
        <Treasures />

        <Leaders />

        <TeamPhoto />

        <div id="vacancies">
          <FullWrapper isMargin={false}>
            <VacancyShortContainer />
          </FullWrapper>
        </div>
        <BottomBanner />
      </>
    );
  }
}

export default MainPage;
