import React from "react";
import "./style.sass";
import FullWrapper from "../../wrappers/full-wrapper";
import DigitalAgency from "../../components/digital-agency";
import DevelopmentsSlider from "../../components/developments-slider";
import WebpractikTeam from "../../components/webpractik-team";
import { historyData } from "../../../assets/data/about-learning/history";
import HistorySlider from "../../components/history-slider";

const aboutLearning = () => {
  return (
    <>
      <div className="about-learning">
        <FullWrapper >
          <h1 className="about-learning-block__title">
            Об агентстве
          </h1>
          <div className="about-learning__title">
            Мы — digital-агентство «Вебпрактик», занимаем 1 место среди
            премиум-агентств интернет-маркетинга и 2 место среди разработчиков
            для корпораций в «Рейтинге Рунета».
          </div>
          <div className="about-learning__info about-learning__info--indent-bottom-md">
            Наши специалисты тестируют десятки гипотез, создают сложные
            высоконагруженные сервисы, разрабатывают маркетинговые
            digital-стратегии для корпораций из самых разных отраслей.
          </div>
        </FullWrapper>
      </div>

      <FullWrapper smallPaddings={false}>
        <DevelopmentsSlider />
      </FullWrapper>

      <FullWrapper smallPaddings={true} className="padding-top">
        <DigitalAgency />
      </FullWrapper>

      <FullWrapper smallPaddings={true} className="history-bg">
        <HistorySlider historyData={historyData} />
      </FullWrapper>

      <FullWrapper smallPaddings={true}>
        <WebpractikTeam />
      </FullWrapper>

      <FullWrapper smallPaddings={true} className="welcome-bg">
        <div className="about-learning__container">
          <div className="about-learning__welcome">
            Мы создаем востребованные ИТ-продукты для решения бизнес-задач
            клиентов и помогаем сотрудникам расти и развиваться внутри компании.
          </div>
          <a
            rel="noopener noreferrer"
            className="about-learning__link"
            href="/digital-learning#bottom-banner"
          >
            Присоединиться к команде
          </a>
        </div>
      </FullWrapper>
    </>
  );
};

export default aboutLearning;
