import React from "react";
import ReactModal from "react-modal";
// import Recording from "../recording";

import "./style.sass";
import FeedBackForm from "../feed-back-form";
import Meetups from "../../pages/meetups";
import MeetupForm from "../meetup-form";
import PresentationForm from "../presentation-form";

type TProps = {
    open: boolean;
    closeModal: any;
    modalData: any;
    eventNew: boolean;
}

const stylesModal = {
    overlay: {
        backgroundColor: "rgba(74, 74, 74, 0.5)",
        zIndex: 1000,
    },
    content: {
        top: "50%",
        right: "auto",
        bottom: "auto",
        left: "50%",
        maxWidth: "1400px",
        width: "100%",
        maxHeight: "100%",
        marginRight: "-50%",
        padding: "0",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
    },
};

const Modal = ({open, closeModal, modalData, eventNew}: TProps) => {
    const {
        title,
        author,
        direction,
        date,
        time,
        info
    } = modalData;

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            style={stylesModal}
            ariaHideApp={false}
        >
            <div className="modal">
                <div className="modal__content">
                    <div className="modal__header">
                        <div
                            className="modal__title">{title}
                        </div>
                        <button className="modal__btn-close"
                                onClick={closeModal}>
                            <svg width="22" height="22" fill="none">
                                <path d="M21 1L1 21m20 0L1 1" stroke="#2A2D33" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </button>
                    </div>
                    <div className="modal__info">
                        <div className="modal__item">
                            <div className="modal__name">
                                Направление:
                            </div>
                            <div className="modal__value modal__value--purple">
                                {direction}
                            </div>
                        </div>
                        <div className="modal__item">
                            <div className="modal__name">
                                Дата и время:
                            </div>
                            <div className="modal__value">
                                {time && time.slice(0, 5)}, {date && date}
                            </div>
                        </div>
                        <div className="modal__item modal__item--full-width">
                            <div className="modal__name">
                                Докладчик:
                            </div>
                            <div className="modal__value">
                                {author}
                            </div>
                        </div>
                        {
                            info &&
                            <div className="modal__item modal__item--full-width">
                              <div className="modal__name">
                                Описание:
                              </div>
                              <div className="modal__value" dangerouslySetInnerHTML={{__html: info}}/>
                            </div>
                        }
                    </div>
                    {
                        eventNew ?
                            <MeetupForm/>
                            :
                            <>
                                <div className="modal__link">
                                    <a className="event-card__btn button button--sm button--nomargin button--transparent"
                                       href="#">
                                        <span>Смотреть видео</span>
                                    </a>
                                </div>
                                <PresentationForm/>
                            </>
                    }


                </div>
            </div>
        </ReactModal>
    );
};

export default Modal;