import React, {Component} from 'react';
import CommonTitle from "../common-title";
import FullWrapper from "../../wrappers/full-wrapper";

import {TodolistInfoItem} from "./todolist-info-item";
import {TodolistMarkerBody} from "./todolist-marker-body";

import './style.sass';

interface IPropsTodolist {
    data: {
        title?: string,
        info: any,
        marker: {
            title: string,
            img: any
        }
    };
    isMarginBottom: boolean
}

class Todolist extends Component<IPropsTodolist, {}> {
    render() {
        const {title, info, marker} = this.props.data;
        return (
            <div className="todolist">
                {
                    <FullWrapper isMargin={this.props.isMarginBottom}>
                        <div className="todolist__item">
                            <CommonTitle className="todolist__title">{title}</CommonTitle>
                            <div className="todolist__info">
                                {
                                    info ?
                                        <div className="todolist__info-list">
                                            {
                                                <TodolistInfoItem info={info}/>
                                            }
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    marker ?
                                        <TodolistMarkerBody marker={marker}/>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </FullWrapper>
                }
            </div>
        );
    }

}

export default Todolist;




