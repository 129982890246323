import React from "react";

import Slider, { Settings } from "react-slick";
import ClassicArrow from "../classic-arrow";

import "./style.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { testData } from "../../../assets/data/about-learning/developments";

const data = () => {
  return testData;
};

const DevelopmentsSlider = () => {
  let slider: any;

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <ClassicArrow
        customClassName="developments-slider__right"
        positionAbsolute
        lastSlide={data()?.length - 1}
      />
    ),
    prevArrow: (
      <ClassicArrow
        customClassName="developments-slider__left"
        positionAbsolute
        isLeft
      />
    ),
  };

  return (
    <>
      <div className="developments-slider">
        <Slider ref={(c) => (slider = c)} {...settings}>
          {data().map((item, index) => (
            <div className="developments-slider__block" key={index}>
              <img
                className="developments-slider__img"
                src={item.img}
                alt="dev-images"
              />
              <div className="developments-slider__container">
                <div className="developments-slider__title">{item.title}</div>
                <div className="developments-slider__subtitle">
                  {item.subtitle}
                </div>
                <a className="developments-slider__link" href={item.link}>
                  Смотреть кейс
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default DevelopmentsSlider;
