import React from 'react';
import FullWrapper from "../../wrappers/full-wrapper";
import FeedBackForm from "../feed-back-form";
import CommonUsualText from "../common-usual-text";

import './style.sass';
import SubscribeForm from "../subscribe-form";

const Subscribe:React.FC = ():JSX.Element => {
    return (
        <div className="subscribe">
            <FullWrapper bigPaddings={true}>
                <div className="subscribe__wrapper">
                    <div className="subscribe__info">
                        <div className="subscribe__title">Подписаться на&nbsp;события</div>
                        <CommonUsualText className="subscribe__text">
                            Просто заполните форму и&nbsp;мы&nbsp;уведомим вас о&nbsp;событиях, которые мы&nbsp;планируем провести.
                        </CommonUsualText>
                    </div>
                    <div className="subscribe__form-container">
                        <SubscribeForm/>
                    </div>
                </div>
            </FullWrapper>
        </div>
    );
};
export default Subscribe;

