import React from "react";
import {Line} from "rc-progress";

export interface LineProgressOptions {
    strokeWidth: number //ширина язычка
    trailWidth: number //ширина полосы
    trailColor: string //цвет полосы
    strokeColor: string,  //цвет язычка
    className: string,
    percent: number//процент
}


export const LineProgress: React.FC<LineProgressOptions> = ({
        strokeWidth,
        trailWidth,
        trailColor,
        strokeColor,
        className,
        percent,
    }) => {
    return (
        <Line
            className={className}
            percent={percent}
            strokeWidth={strokeWidth}
            trailWidth={trailWidth}
            trailColor={trailColor}
            strokeColor={strokeColor}/>
    );
};
