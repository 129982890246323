import React, { FC } from "react";
import Slider, { Settings } from "react-slick";

import {IDevelopmentEvent, IEvent, ILeisure} from "../../../typings/event";

import Event from "../event";
import ClassicArrow from "../classic-arrow";
import LeisureEvent from "../event/LeisureEvent";
import DevelopmentEvent from "../event/DevelopmentEvent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.sass";

interface EventSliderProps {
  events?: IEvent[];
  leisure?: ILeisure[];
  development?: IDevelopmentEvent[];
}

const EventSlider: FC<EventSliderProps> = ({ events = [], leisure = [], development= [] }) => {
  const slidesToShowDesktop = 3;
  const slidesToShowTablet = 2;
  const slidesToShowMobile = 1;

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: 1,
    swipe: false,
    centerPadding: "38px",
    nextArrow: (
      <ClassicArrow
        positionAbsolute
        lastSlide={(events?.length || leisure?.length) - slidesToShowDesktop}
      />
    ),
    prevArrow: <ClassicArrow positionAbsolute isLeft />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: slidesToShowDesktop,
          slidesToScroll: 1,
          nextArrow: (
            <ClassicArrow
              positionAbsolute
              lastSlide={
                (events?.length || leisure?.length) - slidesToShowDesktop
              }
            />
          ),
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShowTablet,
          slidesToScroll: 1,
          nextArrow: (
            <ClassicArrow
              positionAbsolute
              lastSlide={
                (events?.length || leisure?.length) - slidesToShowTablet
              }
            />
          ),
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: slidesToShowMobile,
          slidesToScroll: 1,
          nextArrow: (
            <ClassicArrow
              positionAbsolute
              lastSlide={
                (events?.length || leisure?.length) - slidesToShowMobile
              }
            />
          ),
        },
      },
    ],
  };

  return (
    <div className="events-work__slider">
      <Slider className="events-work__wrapper" {...settings}>
        {events?.length &&
          events.map((event) => <Event key={event.id} {...event} />)}
        {leisure?.length &&
          leisure.map((event) => <LeisureEvent key={event.id} {...event} />)}
	    {development?.length &&
			development.map((event) => <DevelopmentEvent key={event.id} {...event} />)}
      </Slider>
    </div>
  );
};

export default EventSlider;
