import React, { useEffect, useState } from "react";
import MainTitle from "../../components/main-title";
import FullWrapper from "../../wrappers/full-wrapper";
import Todolist from "../../components/todolist";
import Tech from "../../components/tech";
import PhotoSlider from "../../components/photo-slider";
import Conditions from "../../components/conditions";
import Faq from "../../components/faq";
import { SPLIT_TEXT } from "../../../typings/text";
import parser from "html-react-parser";
import "./style.sass";

interface isProps {
  [key: string]: any;

  data: any;
}

const VacancyInner = (props: isProps) => {
  const { bgImage, title, text, data } = props;

  const [blocks, setBlocks] = useState({
    description: "",
    tasks: "",
    expectation: "",
    give: "",
    stages: "",
  });

  useEffect(() => {
    if ((text as string).includes("<p")) {
      const splitting = (text as string).split(".");

      const tasksIndex = splitting.findIndex((el) => el.includes(SPLIT_TEXT.TASKS));
      const expectationIndex = splitting.findIndex((el) => el.includes(SPLIT_TEXT.EXPECTATION));
      const giveIndex = splitting.findIndex((el) => el.includes(SPLIT_TEXT.GIVE));
      const stagesIndex = splitting.findIndex((el) => el.includes(SPLIT_TEXT.STAGES));

      const description = splitting.slice(0, tasksIndex).join(". ");
      const tasks = splitting.slice(tasksIndex, expectationIndex).join(". ");
      const expectation = splitting.slice(expectationIndex, giveIndex).join(". ");
      const give = splitting.slice(giveIndex, stagesIndex).join("");
      const stages = splitting.slice(stagesIndex, splitting.length).join(". ");

      setBlocks({
        description: `${description}.`,
        tasks: `${tasks}.`,
        expectation: `${expectation}.`,
        give: `${give}.`,
        stages: `${stages}.`,
      });
    }
  }, [text]);
  console.log(blocks);

  return (
    <div>
      <MainTitle decor={true} vacancy={true} title={title} text={blocks.description || text} />

      {blocks.tasks && (
        <FullWrapper>
          <div className="vacancy-block">{parser(blocks.tasks)}</div>
        </FullWrapper>
      )}

      {blocks.expectation && (
        <FullWrapper>
          <div className="vacancy-block">{parser(blocks.expectation)}</div>
        </FullWrapper>
      )}

      {blocks.give && (
        <FullWrapper>
          <div className="vacancy-block">{parser(blocks.give)}</div>
        </FullWrapper>
      )}

      {blocks.stages && (
        <div className="vacancy-wrapper">
          <FullWrapper>
            <div className="vacancy-block vacancy-block--stages">{parser(blocks.stages)}</div>
          </FullWrapper>
        </div>
      )}

      {(data["zadachi"] || data["ne_budet"]) && (
        <FullWrapper disableLeftRightPadding={true} smallPaddings={true}>
          {data["zadachi"] && data["zadachi"].info.length > 0 && (
            <Todolist isMarginBottom={data["ne_budet"]?.info?.length > 0} data={data["zadachi"]} />
          )}
          {data["ne_budet"] && data["ne_budet"].info.length > 0 && (
            <Todolist isMarginBottom={false} data={data["ne_budet"]} />
          )}
        </FullWrapper>
      )}

      {data["tech"] && <Tech data={data["tech"]} />}

      {(data["projects"] || data["trebovaniya"] || data["usloviya"] || data["photoSlider"]) && (
        <FullWrapper disableLeftRightPadding={true} smallPaddings={true}>
          {data["projects"] && <Todolist isMarginBottom={true} data={data["projects"]} />}
          {data["trebovaniya"] && <Todolist isMarginBottom={true} data={data["trebovaniya"]} />}
          {data["usloviya"] && <Todolist isMarginBottom={true} data={data["usloviya"]} />}
          {data["photoSlider"] && <PhotoSlider data={data["photoSlider"]} />}
        </FullWrapper>
      )}

      {data["condition"] && <Conditions data={data["condition"]} />}
      {data["faq"] && <Faq data={data["faq"]} />}
    </div>
  );
};

export default VacancyInner;
