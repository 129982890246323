import React from 'react';

import parse from 'html-react-parser';

import './style.sass';

import FullWrapper from '../../wrappers/full-wrapper';
import CommonTitle from '../../components/common-title';
import CommonUsualText from '../../components/common-usual-text';

import img1 from 'svg/svg1.svg';
import img2 from 'svg/svg2.svg';
import img3 from 'svg/svg3.svg';
import img4 from 'svg/svg4.svg';
import img5 from 'svg/svg5.svg';
import img6 from 'svg/svg6.svg';
import img7 from 'svg/svg7.svg';

const arr = [
    {
        img: img1,
        label: 'Команда',
        subtitle:
            'Мы&nbsp;гордимся нашей командой. И&nbsp;это не&nbsp;просто радость от&nbsp;достижений и&nbsp;успехов&nbsp;&mdash; это желание работать вместе и&nbsp;поддерживать друг друга. Общая цель команды&nbsp;&mdash; наш приоритет',
    },
    {
        img: img2,
        label: 'Постоянное развитие',
        subtitle:
            'Технологии и&nbsp;инновации двигают нас вперёд. Мы&nbsp;должны развиваться и&nbsp;стремиться получать новые знания, работая в&nbsp;ИТ-отрасли. На&nbsp;одной волне с&nbsp;теми, кто стремится к&nbsp;профессиональному росту',
    },
    {
        img: img3,
        label: 'Технологическая эффективность',
        subtitle:
            'Мы&nbsp;стремимся привносить лучшие инженерные практики и&nbsp;современные технологии в&nbsp;проекты, с&nbsp;которыми работаем. Активно ищем и&nbsp;экспериментируем с&nbsp;новыми инструментами',
    },
    {
        img: img4,
        label: 'Ориентация на&nbsp;бизнес',
        subtitle: `Клиенты&nbsp;&mdash; это фундамент нашего бизнеса. Мы не&nbsp;только реагируем на&nbsp;потребности партнеров и&nbsp;предвосхищаем их&nbsp;ожидания&nbsp;&mdash; мы&nbsp;измеряем эффективность команды успехами наших клиентов`,
    },
    {
        img: img5,
        label: 'Ответственность',
        subtitle:
            'Мы&nbsp;держим слово и&nbsp;предупреждаем о&nbsp;возможных рисках. Мы&nbsp;не&nbsp;боимся ошибаться, но&nbsp;помним о&nbsp;своей ответственности и&nbsp;не&nbsp;ищем внешние источники проблемы',
    },
    {
        img: img6,
        label: 'Поддержка инициатив',
        subtitle:
            'Мы&nbsp;создаем среду, где нет равнодушия к&nbsp;проблемам: все могут проявить инициативу и&nbsp;внести значимый вклад в&nbsp;общий успех',
    },
    {
        img: img7,
        label: 'Открытость',
        subtitle:
            'Мы&nbsp;открыто обсуждаем трудности, делимся обратной связью и&nbsp;учимся друг у&nbsp;друга. Нам важно, чтобы каждый в&nbsp;команде чувствовал себя ценным и&nbsp;понимал, что может изменить процессы к&nbsp;лучшему',
    },
];

const Treasures = () => {
    return (
        <FullWrapper className="treasures" isMargin={true}>
            <div className="treasures__wrapper" id="treasures">
                <CommonTitle>Ценности</CommonTitle>
                <div className="treasures__container">
                    {arr.map(({ img, label, subtitle }, index) => (
                        <div className="treasures__item" key={index}>
                            <div className="treasures__icon-container">
                                <div className="treasures__icon">
                                    <img src={img} alt="" />
                                </div>
                            </div>
                            <div className="treasures__info">
                                <div className="treasures__label">{parse(label)}</div>
                                <CommonUsualText className="treasures__text">
                                    {parse(subtitle)}
                                </CommonUsualText>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </FullWrapper>
    );
};

export default Treasures;
