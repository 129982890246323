import React from "react";

interface EventDateProps {
  start?: string;
  end?: string;
  completed: boolean;
}

const moreThenOneDay = (start?: string, end?: string) => {
  if (!start || !end) {
    return "";
  }

  if (new Date(start).toDateString() === new Date(end).toDateString()) {
    return new Date(start).toLocaleDateString();
  }

  return `${new Date(start).getDate()}.${new Date(start).getMonth() + 1}-${new Date(end).toLocaleDateString()}`;
};

function EventDate({ start, end, completed }: EventDateProps) {
  return (
    <>
      {!completed && <div className="event__date">{moreThenOneDay(start, end)}</div>}
      {completed && <div className="event__date event__date--end">Завершено</div>}
    </>
  );
}

export default EventDate;
