import React, {Component} from "react";

import './style.sass'

class Cookies extends Component<{acceptedCookiesHandler: () => void}, {}> {
	render() {
		const { acceptedCookiesHandler } = this.props
		
		return (
			<div className='cookies'>
				<div className='cookies__wrapper'>
					<div className='cookies__text'>Мы&nbsp;используем куки для улучшения работы сайта.
						Если вы&nbsp;продолжаете использовать сайт, то&nbsp;соглашаетесь с&nbsp;
						<a href="/files/rules-cookie.pdf" target="_blank" rel="noopener noreferrer">условиями использования куки</a>.</div>
					<button className='cookies__btn' type='button' onClick={acceptedCookiesHandler}>Хорошо</button>
				</div>
			</div>
		);
	}
}

export default Cookies