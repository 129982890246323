import { useEffect, useState } from "react";

function useModal() {
  const [showModal, setShowModal] = useState(false);

  const onToggleModalHandler = () => setShowModal(!showModal);

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "auto";
    document.body.style.touchAction = showModal ? "none" : "auto";
  }, [showModal]);

  return { showModal, onToggleModalHandler };
}

export default useModal;
