import React from 'react';

import './style.sass'

const DigitalAgency = () => {
    return (
        <div className="digital-agency">
            <div className="digital-agency__preview">
                <div
                    className="digital-agency__paragraph digital-agency__paragraph--xl digital-agency__paragraph--indent-bottom">
                    С 2011 года наша команда реализовала 200+ проектов в веб-разработке и performance-маркетинге. Вывела на мировой
                    рынок  цифровые продукты, одним из которых пользуется более 1 млн человек в месяц из 200 стран.
                </div>
            </div>
            <div className="digital-agency__info">
                <div className="digital-agency__paragraph">
                    Имеем собственную лабораторию по созданию цифровых продуктов, где испытываем новые технологии и масштабируем наиболее эффективные решения.
                </div>
            </div>
        </div>
    )
}

export default DigitalAgency;