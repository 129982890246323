import React from "react";
import "./style.sass";
import logo from "../../../svg/header/logo.svg";
import MenuBurger from "../menu-burger";
import BurgerSocial from "../burger-social";
import { NavLink } from "react-router-dom";

interface IProps {
  closeMenu: Function;
}

const linkDownList = [
  // {
  //     link: '/gallery',
  //     linkName: 'Галерея'
  // },
  // {
  //     link: '/office',
  //     linkName: 'Офис'
  // },
  // {
  //     link: '/meetups',
  //     linkName: 'Митапы'
  // },
  {
    link: "https://webpractik.ru/",
    linkName: "webpractik.ru",
    isBlank: true,
  },
];

const BurgerMenu = ({ closeMenu }: IProps) => {
  const onNavigate = () => {
    closeMenu();
  };
  return (
    <div className="burger-menu">
      <div className="burger-menu__wrapper">
        <div className="burger-menu__burger">
          <a href="/" className="burger-menu__logo">
            <img src={logo} alt="Вебпрактик" />
          </a>
        </div>
        <div className="burger-menu__grid">
          <div className="burger-menu__top">
            <div className="burger-menu__menu">
              <MenuBurger closeMenu={closeMenu} />
            </div>
          </div>
        </div>
        <div className="burger-menu__bot">
          <div className="burger-info">
            <div className="burger-info__row">
              <a
                href="mailto:hr@webpractik.ru"
                className="burger-info__email-link"
              >
                hr@webpractik.ru
              </a>
            </div>
            <div className="menu-burger__bot">
              {linkDownList.map(({ link, linkName, isBlank }, index) => (
                <div className="menu-burger__bot-item" key={index}>
                  {isBlank ? (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="menu-burger__bot-link menu-burger__bot-link--icon"
                      dangerouslySetInnerHTML={{ __html: linkName }}
                    />
                  ) : (
                    <NavLink
                      to={link}
                      activeClassName="active"
                      className="menu-burger__bot-link"
                      onClick={onNavigate}
                      dangerouslySetInnerHTML={{ __html: linkName }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <BurgerSocial />
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
