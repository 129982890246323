import React, {Component} from "react";
import {Link} from "react-router-dom";
import CommonTitle from "../common-title";

import './style.sass'
class VacancyShortContainer extends Component {
    render(){
        return (
            <div className="vacancy-short">
                <CommonTitle className="vacancy-short__title">Команде нужны</CommonTitle>
                <div className="vacancy-short__text">
                    Мы открываем новые направления, работаем с проектами  в разных сферах с крупными брендами и корпорациями. Поэтому у нас постоянно появляются новые вакансии  в веб-разработке, интернет-маркетинге, продажах  и управлении проектами.
                </div>
                <Link to="/allvacancies/" className="vacancy-short__button">
                    <span className="vacancy-short__button-label">смотреть вакансии</span>
                </Link>

            </div>
        )
    }
}


export default VacancyShortContainer;