import React, {Component} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import axios from 'axios';

import ErrorInput from "../error-input";

import imgAttach from 'svg/attach.svg';
import iconFile from 'svg/file 2.svg';

import './style.sass';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'слишком короткое имя')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите имя"),
    email: Yup.string()
        .email('введите корректный email')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите email")
})


interface Values {
    name: string | null,
    email: string | null,
    words: string | null,
    addfile: any
}

interface IState {
    fileName: any,
    isSuccess: boolean
}

class FeedBackForm extends Component<{}, IState> {

    state = {
        fileName: "",
        isSuccess: false
    }

    activaseIsSuccess = () => {
        this.setState({
            isSuccess: true
        })
    }


    getBase64(file: any) {
        if (file === null) {
            return Promise.reject("нет файла")
        } else {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
    }


    mySubmit = async (values: Values, actions: FormikHelpers<Values>) => {

        let totalData;

        actions.setSubmitting(true)

        this.getBase64(values.addfile ? values.addfile : null)
            .then((data: any) => {
                let [, file64] = data.split('base64,')
                return file64;
            })
            .catch(err => console.log(err))
            .then((file64) => {
                totalData = {
                    fields: {
                        "TITLE": values.name,
                        "OPENED": "Y",
                        "CATEGORY_ID": 12,
                        "ASSIGNED_BY_ID": 1818,
                        "PROBABILITY": 0,
                        "CURRENCY_ID": "RUB",
                        "OPPORTUNITY": 0,
                        "BEGINDATA": new Date(),
                        "DATA_CREATE": new Date(),
                        "UF_CRM_1590419608": {
                            fileData: values.addfile ? [values.addfile.name, file64] : null
                        },
                        // "UF_CRM_58EF719063D2A" : $_SESSION['ARR_REFERER'],
                        "UF_CRM_58F5E45E004FF": document.cookie,
                        "UF_CRM_1505909277": values.name,
                        "UF_CRM_1505909324": values.email,
                        "UF_CRM_1505909401": values.words,
                        "UF_CRM_1505909470": window.location.href
                    }
                };
                return totalData
            })
            .then((totalData) => {
                axios
                    .post("https://cp.webpractik.ru/rest/1/58pkir8vmteeyte8/crm.deal.add.json", totalData)
            })
            .then(() => actions.resetForm())
            .then(() => actions.setSubmitting(false))
            .then(() => this.activaseIsSuccess())
            .then(() => {
                window.ym && window.ym(71314477,'reachGoal','otclik');
            });
    }

    showFileName = (values: any) => {
        this.setState({
            fileName: values.addfile[0].name
        })
    }

    addFile = (event: any, func: any) => {
        func({target: {name: event.target.name, value: event.target.files[0]}});
    }


    render() {
        return (
            <div className="feed-back-form__wrapper">
                <div className={`feed-back-form__success ${this.state.isSuccess ? 'feed-back-form__success--active' : ''}`}>Ваше сообщение успешно отправлено</div>

                <Formik validationSchema={validationSchema} initialValues={{name: "", email: "", words: "", addfile: {name: ''}}}
                        onSubmit={(values, actions) => {
                            this.mySubmit(values, actions as FormikHelpers<Values>)
                        }}
                >
                    {({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
                        <Form className="feed-back-form">

                            <div className="feed-back-form__top">
                                <label className="feed-back-form__input-container">
                                    <span className="input__name">Имя</span>
                                    <input
                                        name="name"
                                        id="feedbackform__name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        type="text"
                                        className={`input ${touched.name && errors.name
                                            ? "input__error" : ""}`
                                        }
                                    />
                                    <ErrorInput touched={touched.name} message={errors.name}/>
                                </label>

                                <label className="feed-back-form__input-container">
                                    <span className="input__name">email</span>
                                    <input
                                        name="email"
                                        id="feedbackform__email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        type="email"
                                        className={`input ${touched.email && errors.email ? "input__error" : ""}`}
                                    />
                                    <ErrorInput touched={touched.email} message={errors.email}/>
                                </label>

                            </div>
                            <div className="feed-back-form__middle">
                                <label htmlFor="words">
                                    <div className="input__name">Расскажите немного о себе</div>
                                </label>
                                <textarea
                                    className="feed-back-form__form-textarea"
                                    name="words"
                                    value={values.words}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="feed-back-form__bottom">
                                <div className="feed-back-form__add-file-container">
                                    <label className="input__add-file-label">
                                        <img src={imgAttach} alt=""/> Прикрепить файл
                                        <input
                                            multiple
                                            className="input__add-file-input"
                                            onBlur={handleBlur}
                                            onChange={e => this.addFile(e, handleChange)}
                                            name="addfile"
                                            type="file"
                                        />
                                    </label>

                                    <div className="feed-back-form__file-name">
                                        {
                                            values.addfile.name ?
                                                <>
                                                    <img src={iconFile} alt=""/>
                                                    <span>{values.addfile.name}</span>

                                                </>
                                                :
                                                ""
                                        }
                                        {/*<button type="button" onClick={e => this.removeFile(e, handleChange)}>удалить</button>*/}
                                    </div>
                                </div>

                                <button className="button feed-back-form__submit" disabled={isSubmitting} type="submit"><span>отправить</span></button>

                            </div>
                            <div className="feed-back-form__politics">Нажимая на&nbsp;кнопку,
                                вы&nbsp;даете{' '}
                                <a href='/files/WebpractikSoglasie.pdf' target="_blank">
                                    Согласие на&nbsp;обработку персональных данных
                                </a> и&nbsp;соглашаетесь с&nbsp;
                                <a href='/files/WebpractikPolicy.pdf' target="_blank">
                                    Политикой в отношении обработки персональных данных.
                                </a>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}


export default FeedBackForm;
