import React, { useState } from "react";

import arrowLeft from "svg/navigation-arrows/arrow-left.svg";
import arrowRight from "svg/navigation-arrows/arrow-right.svg";
import "./style.sass";
import { useLocation } from "react-router-dom";

type TLinkDownListItem = {
  link: string;
  linkName: string;
  availableLinkList: {
    prev: string;
    next: string;
  };
};

type TLinkDownList = {
  [key: string]: TLinkDownListItem;
};

const BottomNavigation = () => {
  const { pathname } = useLocation();

  const linkDownList: TLinkDownList = {
    "about-learning": {
      link: "/about-learning",
      linkName: "Об Агентстве",
      availableLinkList: {
        prev: "our-events",
        next: "digital-learning",
      },
    },
    "digital-learning": {
      link: "/digital-learning",
      linkName: "Развитие в команде",
      availableLinkList: {
        prev: "about-learning",
        next: "our-events",
      },
    },
    "our-events": {
      link: "/our-events",
      linkName: "Мероприятия",
      availableLinkList: {
        prev: "digital-learning",
        next: "about-learning",
      },
    },
    "all-events": {
      link: "/all-events",
      linkName: "Все выступления",
      availableLinkList: {
        prev: "about-learning",
        next: "digital-learning",
      },
    },
  };

  const availableLinkList =
    linkDownList[pathname.replace(/^\/|\/$/g, "")]?.availableLinkList;
  const prev = linkDownList[availableLinkList.prev];
  const next = linkDownList[availableLinkList.next];

  return (
    <div className="bottom-navigation">
      {prev && (
        <div className="bottom-navigation__container">
          <a href={prev.link} className="bottom-navigation__icon">
            <img src={arrowLeft} alt="" />
          </a>
          <div className="bottom-navigation__title">{prev.linkName}</div>
        </div>
      )}

      {next && (
        <div className="bottom-navigation__container">
          <div className="bottom-navigation__title">{next.linkName}</div>
          <a href={next.link} className="bottom-navigation__icon">
            <img src={arrowRight} alt="" />
          </a>
        </div>
      )}
    </div>
  );
};

export default BottomNavigation;
