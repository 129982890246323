import React from "react";
import {Link} from "react-router-dom";

import useGetEventsData from "./events.hooks";

import FullWrapper from "../../wrappers/full-wrapper";
import BottomBanner from "../../components/bottom-banner";
import Gradient from "../../components/gradient";
import Join from "../../components/join";
import BottomNavigation from "../../components/bottom-navigation";
import CommonTitle from "../../components/common-title";
import EventSlider from "../../components/events-slider";

import youtube from "../../../svg/our-events/youtube.svg";
import telegram from "../../../svg/our-events/telegram.svg";

import "./style.sass";

const OurEvents = () => {
	const {developments, leisure, performances} = useGetEventsData();

	return (
		<>
			<div className="our-events">
				<FullWrapper>
					<h1 className="our-events__header">
						Мероприятия
					</h1>
					<div className="our-events__title">
						Команда Вебпрактик обладает широкой экспертизой в&nbsp;сфере
						разработки, digital-маркетинга, SEO-продвижения, создания продуктов.
					</div>
				</FullWrapper>
			</div>
			
			<FullWrapper className='our-events__section'>
				<div className="our-events__performance">
					<CommonTitle className="our-events__block-title">
						Мы выступаем
					</CommonTitle>
				</div>
				<div className="our-events__subtitle our-events__subtitle--mb">
					Наши эксперты делятся опытом, выступая на&nbsp;различных экспертных
					площадках, и&nbsp;развивают digital-отрасль.
				</div>
				<EventSlider events={performances}/>
				<Link to="/all-events" className="our-events__performance-all">
					Все выступления
				</Link>
			</FullWrapper>
			
			{!!developments.length && (
				<FullWrapper className='our-events__section'>
					<div className="our-events__organize">
						<CommonTitle className="our-events__block-title">
							Мы развиваем
						</CommonTitle>
						<div className="our-events__subtitle our-events__subtitle--mb">
							&laquo;Вебпрактик&raquo; поддерживает комьюнити Rnd PHP и&nbsp;rnd SEO, формирует экспертное
							ИТ-сообщество, содействует повышению качества ИТ-образования в&nbsp;стране.
						</div>
					</div>
					
					<EventSlider development={developments}/>
				</FullWrapper>
			)}
			
			<FullWrapper className='our-events__section'>
				<CommonTitle className="our-events__block-title">
					Мы отдыхаем
				</CommonTitle>
				<div className="our-events__container">
					<div className="our-events__subtitle">
						Мы умеем продуктивно работать и дружно отдыхать — это залог здоровой
						атмосферы в нашей команде.
					</div>
					<div className="our-events__subtitle our-events__subtitle--mb">
						Мы часто проводим время с коллегами: участвуем в спортивных
						мероприятиях, ходим в походы, сражаемся в настолки и даже играем в
						театре.
					</div>
				</div>
				
				<EventSlider leisure={leisure}/>
			</FullWrapper>
			
			<FullWrapper smallPaddings={true} className="contacts-bg">
				<div className="our-events__contacts">
					<div className="our-events__subtitle">
						Будь в курсе событий агентства
					</div>
					<div className="our-events__icons">
						<div>
							<a
								target="_blank"
								href="https://t.me/webpractik_agency"
								rel="noopener noreferrer"
								className="our-events__icon"
							>
								<img src={telegram} alt=""/>
							</a>
						</div>
						<div>
							<a
								target="_blank"
								href="https://www.youtube.com/channel/UCpRYe-aT_kst84vU2XA5Y0Q/videos"
								rel="noopener noreferrer"
								className="our-events__icon"
							>
								<img src={youtube} alt=""/>
							</a>
						</div>
					</div>
				</div>
			</FullWrapper>
			
			{/*<FullWrapper>*/}
			{/*	<div className="our-events__wrapper">*/}
			{/*		<div className="digital-learning__title digital-learning__title--xl">*/}
			{/*			Присоединяйтесь к команде*/}
			{/*		</div>*/}
			{/*		<div className="digital-learning__info digital-learning__info--indent-bottom-xl">*/}
			{/*			Мы ценим профессионалов и способствуем их росту.*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</FullWrapper>*/}
			
			{/*<FullWrapper>*/}
			{/*	<Join/>*/}
			{/*</FullWrapper>*/}
			
			<BottomBanner/>
			<FullWrapper smallPaddings={true}>
				<BottomNavigation/>
			</FullWrapper>
		</>
	);
};

export default OurEvents;
