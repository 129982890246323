import React from 'react';

// @ts-ignore
const Item = ({img}) => {

    return (
        <div className="team-photo__item">
            <div className="team-photo__image">
                <img src={img} alt="Жизнь внутри - Вебпрактик"/>
            </div>
        </div>
    );
};

export default Item;
