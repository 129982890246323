import React, {Component} from 'react';
import FullWrapper from '../../wrappers/full-wrapper';
import './style.sass';


interface IProps {
    bgImage?: string,
    title: string,
}

class MainWindow extends Component<IProps, {}> {

    render() {

        const {bgImage, title,} = this.props;

        return (
            <div className="main-window" style={{backgroundImage: `url(${bgImage})`}}>
                <video autoPlay={true}
                       muted={true}
                       loop={true}
                       controls={false}
                       controlsList="nodownload"
                       src="video-bg.mp4"
                />
                <FullWrapper className="main-window__wrapper " isMargin={false}>
                    <div className="main-window__wrapper-inner">
                        <h1 className="main-window__title">{title}</h1>
                    </div>
                </FullWrapper>
            </div>
        );
    }
};

export default MainWindow;
