import React, {Component} from "react";
import {Link} from 'react-router-dom';
import './style.sass';

interface Iprops {
    index: number;
    label: string,
    title: string,
    subtitle: string,
    img: any,
    link: string,
    need: boolean | string,
    blank: string,
}

class Leader extends Component<Iprops, {}> {

    render() {

        const {title, subtitle, link, img, label, need, blank} = this.props;

        return (
            <div className="leader">
                <div className="leader__img" style={{backgroundImage: `url(${img})`}}/>
                <div className="leader__info">
                    <div className="leader__title">{title}</div>
                    <div className="leader__subtitle">{subtitle}</div>
                    {blank &&
                        <a href={blank} target="_blank" rel="noopener noreferrer" className="leader__link-blank">Проекты команды</a>
                    }
                </div>
            </div>
        );
    }
}

export default Leader;
