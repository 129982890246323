import React, {useState} from 'react';

import FullWrapper from "../../wrappers/full-wrapper";
import CommonTitle from "../common-title";
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './style.sass';

interface isProps {
    data: {
        list: any,
        imgList: {
            [key: string]: any
        }
    }
}

const Conditions = (props: isProps) => {
    const [image, setImage] = useState("");
    const {list, imgList} = props.data;

    return (
        <div className="conditions">
            <FullWrapper bigPaddings={true}>
                <div className="conditions__wrapper">
                    {imgList.length > 0 &&
                    <div className="conditions__photo-container-wrapper">
                        <div className="conditions__photo-container">
                            {imgList.map((item: any, index: number) => (
                             <div key={index} onClick={() => setImage(process.env.REACT_APP_BACK_URL + item.url)} className="conditions__photo-item"
                                 style={{backgroundImage: `url(${process.env.REACT_APP_BACK_URL + item.url})`}}></div>
                            ))
                            }
                        </div>
                    </div>
                    }
                    <div className="conditions__info">
                        <CommonTitle className="conditions__title" gradient={false}>{parse('Мы&nbsp;создаем условия, чтобы вы&nbsp;развивались')}</CommonTitle>
                        <div className="conditions__list">
                            {
                                <ReactMarkdown children={list}/>
                            }
                        </div>
                    </div>
                </div>
            </FullWrapper>

            {image &&
            <Lightbox
                mainSrc={image}
                onCloseRequest={() => setImage("")}
            />
            }

        </div>
    );
};

export default Conditions;