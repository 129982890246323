import React from "react";


import "./style.sass";

interface IProps {
  touched: any,
  message: any
}

const ErrorInput = ({ touched, message } : IProps) => {
  if (!touched) {
    return <div className="form-message invalid">&nbsp;</div>;
  }
  if (message) {
    return <div className="form-message invelid">{message}</div>;
  }
  return <div className="form-message valid">&nbsp;</div>;
};

export default ErrorInput;
