import React, { useRef } from "react";
import FullWrapper from "../../wrappers/full-wrapper";
import Gradient from "../gradient";

import "./style.sass";

import arrow from "svg/main-title-block__arrow.svg";
import clsx from "clsx";

import parser from "html-react-parser";

interface IProps {
  vacancy: boolean;
  decor?: boolean;
  bgImage?: string;
  title: string;
  subTitle?: string;
  text?: string;
  allVacancy?: boolean;
}

function MainTitle(props: IProps) {
  const { vacancy, bgImage, title, subTitle, text, decor } = props;

  const mainBlockRef = useRef<HTMLDivElement>(null);
  const element = typeof window !== "undefined" && mainBlockRef;
  const defaultMargin = 90;

  const onClick = () => {
    if (!element) return;

    const scrollValue = element?.current?.clientHeight;

    if (scrollValue !== undefined) {
      window.scrollTo({
        top: scrollValue - defaultMargin,
        behavior: "smooth",
      });
    }
  };

  return (
    <FullWrapper
      className={clsx("main-title-block", {
        "main-title-block--vacancy": vacancy,
        "main-title-block--decor": decor,
        "main-title-block--padding": !bgImage,
      })}
    >
      <div className={clsx("main-title-block-inner")} ref={mainBlockRef}>
        <div className="main-title-block__wrapper-inner">
          {vacancy && <h4 className="main-title-block__vacancy-title">вакансия</h4>}

          <div>
            <h1 className="main-title-block__title">
              {vacancy ? `${title}` : <Gradient gradient={true}>{title}</Gradient>}
            </h1>
            <h2 className="main-title-block__subtitle">{subTitle}</h2>
            <span className="main-title-block__text">{parser(text || "")}</span>
            <div className="main-title-block__arrow" onClick={onClick}>
              <img src={arrow} alt="title" />
            </div>
          </div>
        </div>

        {!!bgImage && (
          <div className="main-title-block__bg-container">
            <img src={bgImage} alt="" className="main-title-block__bg" />
          </div>
        )}
      </div>
    </FullWrapper>
  );
}

export default MainTitle;
