import React from "react";
import clsx from "clsx";

import { IDevelopmentEvent } from "../../../typings/event";


import "./style.sass";

function DevelopmentEvent({preview, title, subtitle, link}: IDevelopmentEvent) {
	return (
		<>
			{link ? (
				<a
					className='event'
					href={link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="event__wrapper">
						<img
							className="event__img"
							src={`https://back.team.webpractik.ru${preview?.url}`}
							alt={preview?.name}
						/>
					</div>
					<div className="event__title">{title}</div>
					{subtitle && <div className="event__subtitle">{subtitle}</div>}
				</a>
			) :
			<div
				className={clsx("event", {
					"event--disabled": !link,
				})}
			>
				<div className="event__wrapper">
					<img
						className="event__img"
						src={`https://back.team.webpractik.ru${preview?.url}`}
						alt={preview?.name}
					/>
				</div>
				<div className="event__title">{title}</div>
				{subtitle && <div className="event__subtitle">{subtitle}</div>}
			</div>}
		</>
	);
}

export default DevelopmentEvent;
