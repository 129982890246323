import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import petrovich from "petrovich";

import "./style.sass";

interface IProps {
  id: string;
  title: string;
  subtitle: string;
  user: {
    img: any;
    first: string;
    last: string;
    gender: string;
  };
  command: string;
  join: boolean;
  index: number;
  link: string;
}

const VacancyItem: React.FC<IProps> = ({
  join,
  title,
  link,
  subtitle,
  user,
  command,
  index,
  id,
}) => {
  let author;

  const [truncated, setTruncated] = useState("");

  useEffect(() => {
    const stringToArray = subtitle.split(".");
    const truncateIndex = stringToArray.findIndex(
      (el) =>
        el.includes("С какими задачами вам предстоит столкнуться:") ||
        el.includes("С какими задачами вам предстоит столкнуться (всему этому мы научим):")
    );

    const truncate = stringToArray.slice(0, truncateIndex);

    setTruncated(`${truncate.join(". ")}.`);
  }, [subtitle]);

  if (user) {
    author = petrovich(user, "accusative");
    author = author.first + " " + author.last;
  }

  return (
    <div
      className={
        link ? "vacancy-item-wrapper" : "vacancy-item-wrapper vacancy-item-wrapper--disabled"
      }
    >
      {link ? (
        <Link
          to={"/vacancies/" + link}
          target={"_blank"}
          className={`vacancy-item ${!join ? `vacancy-item--not-route` : ""}`}
        >
          <div className="vacancy-item__label">
            <span className="vacancy-item__number">{`${
              index + 1 < 10 ? `0${index + 1}` : index + 1
            }`}</span>
            <span className="vacancy-item__title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="vacancy-item__subtitle" dangerouslySetInnerHTML={{ __html: truncated }} />
        </Link>
      ) : (
        <div className={`vacancy-item ${!join ? `vacancy-item--not-route` : ""}`}>
          <div className="vacancy-item__label">
            <span className="vacancy-item__number">{`${
              index + 1 < 10 ? `0${index + 1}` : index + 1
            }`}</span>
            <span className="vacancy-item__title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="vacancy-item__subtitle" dangerouslySetInnerHTML={{ __html: truncated }} />
        </div>
      )}
      {/*</Link>*/}
      {command && (
        <Link
          to={`/command/${command}/`}
          target={"_blank"}
          className={`vacancy-item__author-container`}
        >
          {user && user.img && (
            <div
              className="vacancy-item__author-img"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_BACK_URL + user.img.url})`,
              }}
            >
              {" "}
            </div>
          )}
          {author && (
            <div
              className="vacancy-item__author-label"
              dangerouslySetInnerHTML={{ __html: `в команду ${author}` }}
            />
          )}
        </Link>
      )}
    </div>
  );
};

export default VacancyItem;
