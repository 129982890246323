import React, { Component } from "react";
import ClassicArrow from "../classic-arrow";
import FullWrapper from "../../wrappers/full-wrapper";
import PhotoSliderItem from "./photo-slider-item";
import { ResponsiveArray, SliderProgressBar } from "../slider-progress-bar";
import "./style.sass";

// import photoList from 'photo-slider.json';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//ДОЛЖНО БЫТЬ ЧЕТЫРЕ ЭЛЕМЕНТА В МАССИВЕ
const responsive: ResponsiveArray = [
  {
    breakpoint: 4000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 750,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    },
  },
];

interface IProps {
  data?: any;
}

interface IState {
  image?: string;
  imgList?: any;
}

class PhotoSlider extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      image: "",
      imgList: [],
    };
  }

  componentDidMount = () => {
    this.props.data.imgList.map((item: any) => {
      this.state.imgList.push({
        photo: process.env.REACT_APP_BACK_URL + item.url,
      });
    });
  };

  render() {
    return (
      <FullWrapper className="photo-slider">
        <div className="photo-slider__wrapper">
          <SliderProgressBar
            ResponsiveOptions={responsive}
            classes={{
              arrowsContainerClass: "photo-slider__arrows",
              sliderItemClass: "photo-slider__item-out",
              sliderListClass: "photo-slider__list",
              progressBarLineCLass: "photo-slider__brogress-bar",
              navigationsContainerClass: "photo-slider__navigations",
            }}
            renderSliderPrevArrow={(changeSlide: any) => (
              <ClassicArrow isLeft={true} onClick={changeSlide} />
            )}
            renderSliderNextArrow={(changeSlide: any) => (
              <ClassicArrow onClick={changeSlide} />
            )}
            renderSliderItem={(item: any) => (
              <PhotoSliderItem
                onClick={() => this.setState({ image: item.photo })}
                photo={item.photo}
                index={item.index}
              />
            )}
            listSlider={this.state.imgList}
            LineProgressOptions={{
              className: "photo-slider__brogress-bar",
              percent: 0,
              strokeWidth: 0.5,
              trailWidth: 0.5,
              trailColor: "#dcdfe5",
              strokeColor: "rgb(255,8,10)",
            }}
          />
          {this.state.image && (
            <Lightbox
              mainSrc={this.state.image}
              onCloseRequest={() => this.setState({ image: "" })}
            />
          )}
        </div>
      </FullWrapper>
    );
  }
}

export default PhotoSlider;
