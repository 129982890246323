import React from 'react';
import './style.sass';
import { NavLink } from 'react-router-dom';

type TLinkDownList = {
    link: string;
    linkName: string;
};

export const linkDownList: TLinkDownList[] = [
    {
        link: '/about',
        linkName: 'Об Агентстве',
    },
    {
        link: '/digital-learning',
        linkName: 'Развитие в команде',
    },
    {
        link: '/our-events',
        linkName: 'Мероприятия',
    },
];

export default class extends React.Component {
    render() {
        return (
            <div className="header-menu">
                <div className="header-menu__wrapper">
                    <div className="header-menu__grid">
                        {linkDownList.map(({ link, linkName }, index) => (
                            <div className="header-menu__item" key={index}>
                                <div className="header-menu__item-wrap">
                                    {/*{isBlank ? (*/}
                                    {/*  <a*/}
                                    {/*    href={link}*/}
                                    {/*    target="_blank"*/}
                                    {/*    rel="noopener noreferrer"*/}
                                    {/*    className="header-menu__link"*/}
                                    {/*    dangerouslySetInnerHTML={{ __html: linkName }}*/}
                                    {/*  />*/}
                                    {/*) : (*/}
                                    <NavLink
                                        to={link}
                                        activeClassName="active"
                                        className="header-menu__link"
                                        dangerouslySetInnerHTML={{ __html: linkName }}
                                    />
                                    {/*)}*/}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
