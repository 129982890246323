import React, { Component } from "react";

import "./style.sass";

import FullWrapper from "../../wrappers/full-wrapper";
import CommonTitle from "../../components/common-title";
import Leader from "../../components/leader";
import {
  SliderProgressBar,
  ResponsiveArray,
} from "../../components/slider-progress-bar";
import ClassicArrow from "../../components/classic-arrow";
import dataSlider from "../../../leaders.json";

//ДОЛЖНО БЫТЬ ЧЕТЫРЕ ЭЛЕМЕНТА В МАССИВЕ
const responsive: ResponsiveArray = [
  {
    breakpoint: 4000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 750,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
    },
  },
];

class Leaders extends Component<{}, {}> {
  render() {
    return (
      <div className="main-page__leaders" id="leaders">
        <FullWrapper isMargin={true}>
          <CommonTitle>Лидеры Команд</CommonTitle>

          <SliderProgressBar
            ResponsiveOptions={responsive}
            classes={{
              arrowsContainerClass: "leaders__arrows",
              sliderItemClass: "leaders__item",
              sliderListClass: "leaders__list",
              progressBarLineCLass: "leaders__brogress-bar",
              navigationsContainerClass: "leaders__navigations",
            }}
            renderSliderPrevArrow={(
              changeSlide: any,
              lastSlide: number,
              currentSlide: number
            ) => (
              <ClassicArrow
                isLeft={true}
                onClick={changeSlide}
                lastSlide={lastSlide}
                currentSlide={currentSlide}
              />
            )}
            renderSliderNextArrow={(
              changeSlide: any,
              lastSlide: number,
              currentSlide: number
            ) => (
              <ClassicArrow
                onClick={changeSlide}
                lastSlide={lastSlide}
                currentSlide={currentSlide}
              />
            )}
            renderSliderItem={(props: any) => <Leader {...props} />}
            listSlider={dataSlider}
            LineProgressOptions={{
              className: "leaders__brogress-bar",
              percent: 0,
              strokeWidth: 0.5,
              trailWidth: 0.5,
              trailColor: "#dcdfe5",
              strokeColor: "rgba(121, 39, 224, 1)",
            }}
          />
        </FullWrapper>
      </div>
    );
  }
}

export default Leaders;
