import React from 'react';

import './style.sass';
import FullWrapper from '../../wrappers/full-wrapper';

const History = () => {
    return (
        <FullWrapper className="history" isMargin={false}>
            <div className="history__wrapper" id="history">
                <div className="history__container">
                    <div className="history__content">
                        <div className="history__text">
                            С&nbsp;2009 года мы&nbsp;создаем и&nbsp;продвигаем ИТ-продукты,
                            используем лучшие технологии, развиваем себя, компании в&nbsp;цифровом
                            мире. Мы&nbsp;гордимся, что результаты этой работы приносят пользу
                            клиентам, отрасли, обществу и&nbsp;развивают нашу страну.
                        </div>
                        <div className="history__img">
                            <img src="../history2.png" alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </FullWrapper>
    );
};

export default History;
