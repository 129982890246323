import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./style.sass";

import imgLogo from "svg/header/logo.svg";
import imgLogoWhite from "svg/header/logo-white.svg";
import tg from "svg/header/tg.svg";
import tgWhite from 'svg/header/tg-white.svg'
import HeaderMenu from "./header-menu";
import BurgerMenu from "./burger-menu";
import img5 from "../../svg/header/header__icon5.svg";

interface HeaderProps {
  isScrollYMoove?: boolean;
  isMain: boolean;
}

interface IState {
  openMenu: boolean;
}

class Header extends Component<HeaderProps, IState> {
  state = {
    openMenu: false,
  };

  handleClick() {
    this.setState((state) => {
      document.body.style.overflow = state.openMenu ? "auto" : "hidden";
      return { openMenu: !state.openMenu };
    });
  }

  closeMenu = () => {
    document.body.removeAttribute("style");
    this.setState({ openMenu: false });
  };

  render() {
    const isMainClass = this.props.isMain ? "header--main" : "";
    const { openMenu } = this.state;

    return (
      <div
        className={`header ${isMainClass} ${
          !this.props.isScrollYMoove ? "header--transparent" : ""
        }`}
      >
        <div className={"header__mobail-menu " + (openMenu ? "active" : "")}>
          <div className="burger-menu__bg" onClick={this.closeMenu} />
          <div className="header__mobail-wrap">
            <div
              className={
                "header-burger header-burger--mobail " +
                (openMenu ? "active" : "")
              }
              onClick={this.handleClick.bind(this)}
            >
              <div className="header-burger__wrap">
                <span className="header-burger__global header-burger__global--top" />
                <span className="header-burger__global header-burger__global--middle" />
                <span className="header-burger__global header-burger__global--bottom" />
              </div>
            </div>
            <BurgerMenu closeMenu={this.closeMenu} />
          </div>
        </div>

        <div className="header__wrapper">
          <div className="header__grid">
            <div className="header__item header__item--burger">
              <div
                className={"header-burger " + (openMenu ? "active" : "")}
                onClick={this.handleClick.bind(this)}
              >
                <div className="header-burger__wrap">
                  <span className="header-burger__global header-burger__global--top" />
                  <span className="header-burger__global header-burger__global--middle" />
                  <span className="header-burger__global header-burger__global--bottom" />
                </div>
              </div>
            </div>
            <div className="header__item header__item--center">
              <div className="header-center">
                <div className="header-center__item header-center__item--left">
                  <div className="header__logo" style={{ animation: "none" }}>
                    <Link
                      className="header__logo-img header__logo-img--color"
                      to="/"
                    >
                      <img src={imgLogo} alt="Вебпрактик Team" />
                    </Link>
                    <Link
                      className="header__logo-img header__logo-img--white"
                      to="/"
                    >
                      <img src={imgLogoWhite} alt="Вебпрактик Team" />
                    </Link>
                  </div>
                </div>
                <div className="header-center__item header-center__item--right">
                  <div className="header-center__menu">
                    <HeaderMenu />
                  </div>
                  <div className="header-center__btn">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://t.me/webpractik_agency"
                      className="header-center__image"
                    >
                      {this.props.isMain ?
                          <>
                            <img src={tg} alt="" className='header-center__image-purple-image'/>
                            <img src={tgWhite} alt="" className='header-center__image-white-image'/>
                          </>
                          : <img src={tg} alt=""/>}
                    </a>
                    <Link
                        to="/allvacancies/"
                      className="button button--nomargin"
                    >
                      <span>Вакансии</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
