import React, {Component} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import ErrorInput from "../error-input";
import imgAttach from 'svg/attach.svg';
import iconFile from 'svg/file 2.svg';
import axios from 'axios';

import './style.sass';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'слишком короткое имя')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите имя"),
    messenger: Yup.string()
        .min(2, 'слишком короткое имя')
        .max(255, 'слишком длинное имя')
        .required("пожалуйста введите telegram/whatsapp")
})


interface Values {
    name: string | null,
    messenger: string | null,
}

interface IState {
    isSuccess: boolean
}

class MeetupForm extends Component<{}, IState> {
    state = {
        isSuccess: false
    }

    activaseIsSuccess = () => {
        this.setState({
            isSuccess: true
        })
    }
    

    mySubmit = async (values: Values, actions: FormikHelpers<Values>) => {

        const totalData = {
            fields: {
                "TITLE": values.name,
                "MESSENGER": values.messenger,
                "OPENED": "Y",
                "CATEGORY_ID": 12,
                "ASSIGNED_BY_ID": 1818,
                "PROBABILITY": 0,
                "CURRENCY_ID": "RUB",
                "OPPORTUNITY": 0,
                "BEGINDATA": new Date(),
                "DATA_CREATE": new Date(),
                "UF_CRM_58F5E45E004FF": document.cookie,
                "UF_CRM_1505909470": window.location.href
            }
        };

        actions.setSubmitting(true)

        axios
            // .post("https://cp.webpractik.ru/rest/1/58pkir8vmteeyte8/crm.deal.add.json", totalData)
            .post(`${process.env.REACT_APP_BACK_URL + '/meetups'}`, totalData)
            .then(() => {actions.resetForm()})
            .then(() => actions.setSubmitting(false))
            .then(() => this.activaseIsSuccess());
    }


    render() {
        return (
            <div className="meetup-form__wrapper">
                <div className={`meetup-form__success ${this.state.isSuccess ? 'meetup-form__success--active' : ''}`}>Ваше сообщение успешно отправлено</div>

                <Formik validationSchema={validationSchema} initialValues={{name: "", messenger: ""}}
                        onSubmit={(values, actions) => {
                            this.mySubmit(values, actions as FormikHelpers<Values>)
                        }}
                >
                    {({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
                        <Form className="meetup-form">
                            <div className="meetup-form__title">
                                Записаться
                            </div>
                            <div className="meetup-form__top">
                                <label className="meetup-form__input-container">
                                    <span className="input__name">Имя</span>
                                    <input
                                        name="name"
                                        id="feedbackform__name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        type="text"
                                        className={`input ${touched.name && errors.name
                                            ? "input__error" : ""}`
                                        }
                                    />
                                    {
                                        errors.name && <ErrorInput touched={touched.name} message={errors.name}/>
                                    }
                                </label>

                                <label className="meetup-form__input-container">
                                    <span className="input__name">telegram/whatsapp</span>
                                    <input
                                        name="messenger"
                                        id="feedbackform__messenger"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.messenger}
                                        type="text"
                                        className="input"
                                    />
                                </label>

                            </div>

                            <div className="meetup-form__bottom">
                                <button className="button meetup-form__submit" disabled={isSubmitting} type="submit"><span>отправить</span></button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}


export default MeetupForm;
