import React, { Component } from "react";
import axios from "axios";

import { RouteComponentProps } from "react-router";

import BottomBanner from "../../components/bottom-banner";

import "./style.sass";

import bg from "pic/main-title-block__bg-vacancy.png";

import Page404 from "../error";
import VacancyInner from "../VacancyInner";

interface RouteParams {
  type: string;
}

type TVacancies = {
  [key: string]: any;
};

interface IState {
  post: {
    author: string;
    id: string;
    img: string;
    subtitle: string;
    title: string;
  };
  vacancies: TVacancies;
  error: null;
  loaded: boolean;
}

class Vacancy extends Component<RouteComponentProps<RouteParams>, IState> {
  state = {
    post: {
      author: "",
      id: "",
      img: "",
      subtitle: "",
      title: "",
    },
    vacancies: {} as TVacancies,
    error: null,
    loaded: false,
  };

  componentDidMount = async () => {
    try {
      let response = await axios.get(`https://back.team.webpractik.ru/vacancies`);
      // let response = await axios.get(`${process.env.REACT_APP_BACK_URL + "/vacancies"}`);

      const renameKey = (object: any, key: any, newKey: any) => {
        const clonedObj = clone(object);
        const targetKey = clonedObj[key];
        delete clonedObj[key];
        clonedObj[newKey] = targetKey;

        return clonedObj;
      };

      const clone = (obj: any) => Object.assign({}, obj);

      for (let key in response.data) {
        response.data = renameKey(response.data, key, response.data[key].type);
      }
      this.setState({ vacancies: response.data, loaded: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error, vacancies, loaded } = this.state;

    const typeVacancy: string = this.props.match.params.type || "";
    const currentVacancy: any = vacancies[typeVacancy];

    if (error) return <p>Ошибка</p>;

    if (!loaded) return <p></p>;

    if (!currentVacancy) return <Page404 />;
    return (
      <div>
        <VacancyInner
          bgImage={bg}
          title={currentVacancy["title"]}
          text={currentVacancy["text"]}
          data={currentVacancy}
        />
        <BottomBanner />
      </div>
    );
  }
}

export default Vacancy;
