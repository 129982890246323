import React from "react";

import { TFilter } from "../../../typings/event";
import "./style.sass";
import clsx from "clsx";

interface EventFilterProps {
  currentFilter: TFilter;
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

function EventFilter({ currentFilter, onClick }: EventFilterProps) {
  return (
    <div className="event-filter">
      <button
        onClick={onClick}
        className={clsx("event-filter__btn", {
          "event-filter__active": currentFilter === "ALL",
        })}
        data-type="ALL"
      >
        все
      </button>
      <button
        onClick={onClick}
        className={clsx("event-filter__btn", {
          "event-filter__active": currentFilter === "DEVELOPMENT",
        })}
        data-type="DEVELOPMENT"
      >
        разработка
      </button>
      <button
        onClick={onClick}
        className={clsx("event-filter__btn event-filter__btn--order", {
          "event-filter__active": currentFilter === "MARKETING",
        })}
        data-type="MARKETING"
      >
        маркетинг
      </button>
      <button
        onClick={onClick}
        className={clsx("event-filter__btn", {
          "event-filter__active": currentFilter === "SEO",
        })}
        data-type="SEO"
      >
        seo
      </button>
    </div>
  );
}

export default EventFilter;
