import React, {useState, useEffect} from 'react';
import axios from 'axios';
import clsx from 'clsx';

import CommonTitle from '../common-title';
import FullWrapper from '../../wrappers/full-wrapper';
import EventCard from './../event-card';
import './style.sass';
import PlugMeetup from "../plug";

type TData = Array<any>;

const Events = () => {
    const [data, setData] = useState<TData>([]);
    const [dataDevelopFuture, setDataDevelopFuture] = useState([]);
    const [dataMarketingFuture, setDataMarketingFuture] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [departament, setDepartament] = useState('developer');

    const handleClick = (value: string) => {
        setDepartament(value);
    };

    const getData = async () => {
        try {
            // let response = await axios.get(`${process.env.REACT_APP_BACK_URL + '/meetups'}`);
            let response = await axios.get(`${process.env.REACT_APP_BACK_URL + '/meetups'}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData()
            .then(data => {
                if (data) {
                    setData(data);
                    setDataDevelopFuture(data.filter((item: any) => item.departament == 'developer' && item.future !== null));
                    setDataMarketingFuture(data.filter((item: any) => item.departament == 'marketing' && item.future !== null));
                }
            })
            .finally(() => {
                setLoaded(true);
            });

        return () => {
            setLoaded(false);
        };
    }, []);

    return loaded ? (
        <FullWrapper>
            <div className="events">
                <CommonTitle className="mission__title">Митапы</CommonTitle>
                <div className="events__tabs">
                    <button
                        className={clsx(
                            'events__tab button button--nomargin',
                            departament === 'developer' && 'button--transparent'
                        )}
                        onClick={() => handleClick('developer')}
                    >
                        <span>Разработка</span>
                    </button>
                    <button
                        className={clsx(
                            'events__tab button button--nomargin',
                            departament === 'marketing' && 'button--transparent'
                        )}
                        onClick={() => handleClick('marketing')}
                    >
                        <span>Маркетинг</span>
                    </button>
                </div>
                <div className="events__section">
                    <div className="events__title">Будущие события</div>
                    {
                        departament === 'developer' && <div className="events__content">
                            {dataDevelopFuture.length > 0 ? data.map((item, index) => {
                                const future = item.future;
                                console.log(dataDevelopFuture);
                                return (
                                    future &&
                                    item.departament === departament && (
                                        <EventCard eventNew={future} eventData={item} key={index}/>
                                    )
                                );
                            }) : <PlugMeetup/>}
                        </div>
                    }
                    {
                        departament === 'marketing' && <div className="events__content">
                            {dataMarketingFuture.length > 0 ? data.map((item, index) => {
                                const future = item.future;
                                console.log(dataDevelopFuture);
                                return (
                                    future &&
                                    item.departament === departament && (
                                        <EventCard eventNew={future} eventData={item} key={index}/>
                                    )
                                );
                            }) : <PlugMeetup/>}
                        </div>
                    }
                </div>
                <div className="events__section">
                    <div className="events__title">Прошедшие события</div>
                    <div className="events__content">
                        {data && data.map((item, index) => {
                            const future = item.future;

                            return (
                                future == null &&
                                item.departament === departament && (
                                    <EventCard eventNew={future} eventData={item} key={index}/>
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        </FullWrapper>
    ) : (
        <>Загрузка...</>
    );
};

export default Events;
