import React from "react";

import "./style.sass";

import gradingSystemImg from "../../../pic/grading-system.jpg";
import GradingSystemItem, { IGradingSystemItem } from "../grading-system-item";

const listLeft: IGradingSystemItem[] = [
  {
    name: "Общий прогресс",
    info:
      "Диаграмма показывает, сколько процентов осталось до следующего уровня",
  },
  {
    name: "Прогресс по отдельным компетенциям",
  },
  {
    name: "Прогресс за прошлый период",
  },
];

const listRight: IGradingSystemItem[] = [
  {
    name: "Информация о наставнике",
  },
  {
    name: "Учебный календарь",
  },
  {
    name: "Планировщик",
    info: "Ученик сам планирует свои активности",
  },
];

const GradingSystem = () => {
  return (
    <div className="grading-system">
      <div className="grading-system__column grading-system__column--left">
        {listLeft.map((item, index) => {
          return <GradingSystemItem {...item} key={index} />;
        })}
      </div>
      <img
        className="grading-system__img"
        src={gradingSystemImg}
        alt="grading-system"
      />
      <div className="grading-system__column grading-system__column--right">
        {listRight.map((item, index) => {
          return <GradingSystemItem {...item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default GradingSystem;
