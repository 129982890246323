import React from "react";

interface IProps {
    caption?: string,
    url?: any,
    alternativeText?: string
}

const TechListItem: React.FC<IProps> = ({caption, url, alternativeText}) => {
    return (
        <div className="tech-list__item-in">
            <img className="tech-list__img" src={process.env.REACT_APP_BACK_URL + url} alt={alternativeText}/>
            <span className="tech-list__item-title">{caption}</span>
        </div>
    );
};

export default TechListItem;
