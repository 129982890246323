import React, { useEffect } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";

import CloseIcon from "svg/close.svg";

interface EventModalProps {
  title: string;
  link: string;
  show: boolean;
  onClose: () => void;
}

function EventModal({ link, title, show, onClose }: EventModalProps) {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <Modal
      className="event__modal"
      overlayClassName="event__modal-overlay"
      isOpen={show}
      onRequestClose={onClose}
      preventScroll
    >
      <div className="event__modal-close" onClick={onClose}>
        <img src={CloseIcon} alt="close" />
      </div>
      <div className="event__modal-title">{title}</div>
      <ReactPlayer light width="100%" controls url={link} />
    </Modal>
  );
}

export default EventModal;
