import React, { useState } from 'react';

import plugImg from '../../../pic/plug-img.png'
import './style.sass';

const PlugMeetup = () => {

    return (
        <div className="plug-meetup">
            <div className="plug-meetup__main">
                <div className="plug-meetup__block_text">
                    <span>Митап в разработке, следите за обновлениями</span>
                </div>
                <div className="plug-meetup__img">
                    <img src={plugImg} alt='митап в разработке' />
                </div>
            </div>
        </div>
    );
};

export default PlugMeetup;
