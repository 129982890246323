import React from 'react';

import './style.sass';

interface IProps {
    gradient?: boolean,
    children: any
}


const Gradient:React.FC<IProps> = ({gradient= true, children}) => {


    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    return isSafari ?
        <span className={`${gradient ? 'safari-support-gradient' : '' }`}>{children}</span>
        :
        <span className={`${gradient ? 'gradient' : '' }`}>{children}</span>
};

export default Gradient;
