import React from "react";
import clsx from "clsx";
import { CustomArrowProps } from "react-slick";

import "./style.sass";

interface IProps extends CustomArrowProps {
  onClick?: any;
  isLeft?: boolean;
  isGray?: boolean;
  disabled?: boolean;
  positionAbsolute?: boolean;
  lastSlide?: number;
  customClassName?: string;
}

const ClassicArrow: React.FC<IProps> = ({
  onClick,
  isLeft = false,
  isGray,
  disabled = false,
  positionAbsolute,
  currentSlide,
  lastSlide,
  customClassName,
}) => (
  <button
    className={clsx(
      "classic-arrow",
      {
        "classic-arrow--left": isLeft,
        "classic-arrow--gray": isGray,
        "classic-arrow--abs": positionAbsolute,
        "classic-arrow--left-abs": positionAbsolute && isLeft,
      },
      customClassName
    )}
    onClick={onClick}
    type="button"
    disabled={
      disabled ||
      (currentSlide === 0 && isLeft) ||
      (currentSlide === lastSlide && !isLeft)
    }
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#ff3607"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C8 0.447716 8.44771 0 9 0C9.55229 0 10 0.447715 10 1V14.4849L15.7778 8.70711C16.1683 8.31658 16.8014 8.31658 17.192 8.70711C17.5825 9.09763 17.5825 9.7308 17.192 10.1213L10.1209 17.1924C10.0659 17.2474 10.0061 17.2946 9.94281 17.3342C9.80529 17.7221 9.4351 18 9 18C8.59053 18 8.23854 17.7539 8.08388 17.4015C7.97287 17.353 7.86888 17.2832 7.77802 17.1924L0.706956 10.1213C0.316431 9.7308 0.316431 9.09763 0.706956 8.70711C1.09748 8.31658 1.73064 8.31658 2.12117 8.70711L8 14.5859V1Z"
      />
    </svg>
  </button>
);

export default ClassicArrow;
