import React, {Component} from 'react';
import VacancyContainer from "../../components/vacancy-container";
import {RouteComponentProps} from "react-router";
import FullWrapper from "../../wrappers/full-wrapper";
import BottomBanner from "../../components/bottom-banner";

import "./style.sass";


interface RouteParams {
	option: string
}

interface IState {
	data: any,
	option: string
}


class Vacancies extends Component<RouteComponentProps<RouteParams>, IState> {
	
	render() {
		return (
			<section className="vacancies">


				<FullWrapper className="command-leader__container-vacancy" bigPaddings={true}>
					<h1 className="vacancies__main-title">
						Вакансии в компании
					</h1>
					<VacancyContainer
						LinkToAll={false}
						needTitle={false}
						commandTitle={'all'}
						join={true}
						firstNeedNowVacancy={true}
						needToggle={true}
					/>
				</FullWrapper>

				<BottomBanner/>

			</section>
		);
	}
}

export default Vacancies;
