import React from 'react';

import Gradient from "../gradient";

import './style.sass';

interface PCommonTitle {
    children: any;
    className?: string | null;
    gradient?: boolean;
}

const CommonTitle:React.FC<PCommonTitle> = ({ children, className = '', gradient= true }) => {

    return (

            gradient ?
                <h2 className={`common-title ${className}`}>
                    <Gradient gradient={gradient}>{children}</Gradient>
                </h2>
                :
                <h2 className={`common-title ${className}`}>
                    {children}
                </h2>

    )
};

export default CommonTitle;
