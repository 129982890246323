import clsx from "clsx";
import React, { ReactNode } from "react";

interface PaginationPageProps {
  handlePagination: () => void;
  children: ReactNode;
  isActive?: boolean;
}

function PaginationPage({ children, handlePagination, isActive = false }: PaginationPageProps) {
  return (
    <button
      className={clsx("pagination__page", {
        "pagination__page--active": isActive,
      })}
      onClick={handlePagination}
      type="button"
    >
      {children}
    </button>
  );
}

export default PaginationPage;
