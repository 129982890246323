import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

// import MainTitle from './modules/components/main-title';
// import VacancyContainer from "./modules/components/vacancy-container";
import Vacancy from './modules/pages/vacancy';
// import Leaders from "./modules/components-layouts/leaders";
import Header from './modules/header/header';
import Footer from './modules/footer/footer';
import Page404 from './modules/pages/error';
import MainPage from './modules/pages/main-page';
import CommandLeader from './modules/pages/comand-leader';
import Vacancies from './modules/pages/vacancies';
import Meetups from './modules/pages/meetups';
import DigitalLearning from './modules/pages/digital-learning';
import ScrollTop from './modules/components/scroll-top';
import Cookies from './modules/components/cookies';
import AboutLearning from './modules/pages/about-learning';
import OurEvents from './modules/pages/events';
import AllEvents from './modules/pages/all-events';
// import {YMInitializer} from 'react-yandex-metrika';

// import FullWrapper from "./modules/wrappers/full-wrapper";

class App extends Component<{}, { isScrollYMoove: boolean; acceptedCookies: boolean }> {
    state = {
        isScrollYMoove: false,
        acceptedCookies: false,
    };

    handleThemeChange = (event: MediaQueryListEvent | MediaQueryList) => {
        const iconLink = document.querySelector('link[rel="icon"]') as HTMLAnchorElement;
        if (!iconLink) {
            return;
        }
        if (event.matches) {
            //темная тема
            iconLink.href = '/favicon-dark.ico';
        } else {
            //светлая тема
            iconLink.href = '/favicon.ico';
        }
    };

    activateTransparentHeader = () => {
        if (window.pageYOffset >= 400 && !this.state.isScrollYMoove) {
            this.setState({
                isScrollYMoove: true,
            });
        }
        if (window.pageYOffset <= 400 && this.state.isScrollYMoove) {
            this.setState({
                isScrollYMoove: false,
            });
        }
    };

    setAcceptedCookies = () => {
        const cookiesLife = new Date(new Date().setMonth(new Date().getMonth() + 1)).toUTCString();

        this.setState({ acceptedCookies: true });

        document.cookie = `acceptedCookies=true; expires=${cookiesLife}`;
    };

    componentDidMount() {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        if (document.cookie.includes('acceptedCookies')) {
            this.setState({ acceptedCookies: true });
        }

        this.handleThemeChange(darkModeMediaQuery);
        darkModeMediaQuery.addEventListener('change', this.handleThemeChange);
        window.addEventListener('scroll', this.activateTransparentHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.activateTransparentHeader);
    }

    render() {
        return (
            <BrowserRouter>
                <ScrollTop>
                    <div className="wrapper-inner">
                        <div className="middle">
                            <Switch>
                                <Route exact path="/">
                                    <Header
                                        isScrollYMoove={this.state.isScrollYMoove}
                                        isMain={true}
                                    />
                                </Route>
                                <Route exact path="*">
                                    <Header
                                        isScrollYMoove={this.state.isScrollYMoove}
                                        isMain={false}
                                    />
                                </Route>
                            </Switch>
                            <div className="content">
                                <Switch>
                                    <Route exact path="/" component={MainPage} />
                                    <Route exact path="/vacancies/:type" component={Vacancy} />
                                    <Route
                                        exact
                                        path="/command/:commandName"
                                        component={CommandLeader}
                                    />
                                    <Route exact path="/allvacancies/" component={Vacancies} />
                                    <Route exact path="/meetups/" component={Meetups} />
                                    <Route exact path="/our-events/" component={OurEvents} />
                                    <Route exact path="/all-events/" component={AllEvents} />
                                    <Route
                                        exact
                                        path="/digital-learning/"
                                        component={DigitalLearning}
                                    />
                                    <Route exact path="/about/" component={AboutLearning} />
                                    <Route path="/about-learning/">
                                        <Redirect to="/about/" />
                                    </Route>
                                    <Route exact path="*" component={Page404} />
                                </Switch>
                                {!this.state.acceptedCookies && (
                                    <Cookies acceptedCookiesHandler={this.setAcceptedCookies} />
                                )}
                            </div>
                        </div>
                        <Footer />
                    </div>
                    {/*<YMInitializer accounts={[71314477]} options={{defer: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}} version="2"/>*/}
                </ScrollTop>
            </BrowserRouter>
        );
    }
}

export default App;
