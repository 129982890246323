import React, { Component } from "react";
import FullWrapper from "../../wrappers/full-wrapper";
import SvgMaskedIcon from "../svg-masked-icon";
import iconAlina from "pic/bottom-banner-img.jpg";
import FeedBackForm from "../feed-back-form";

import "./style.sass";

class BottomBanner extends Component {
  render() {
    return (
      <FullWrapper
        bigPaddings={true}
        className="bottom-banner"
        id="bottom-banner"
      >
        <div className="bottom-banner__wrapper">
          <div className="bottom-banner__top">
            <div className="bottom-banner__info-container">
              <div className="bottom-banner__title">
                Поговорим о вашем развитии?
              </div>
              <div className="bottom-banner__author">
                <div className="bottom-banner__icon">
                  <SvgMaskedIcon index={1} imgMask={iconAlina} />
                </div>
                <div className="bottom-banner__label">
                  <div className="bottom-banner__label-text">
                    Привет, я&nbsp;HR&nbsp;Алина!
                    <br /> Я&nbsp;знаю, как раскрыть ваш потенциал. Напишите
                    о&nbsp;себе или задайте вопрос, и&nbsp;я&nbsp;вам отвечу.
                  </div>
                  <a
                    className="bottom-banner__label-link"
                    href="mailto:hr@webpractik.ru"
                  >
                    hr@webpractik.ru
                  </a>
                </div>
              </div>
            </div>
            <div className="bottom-banner__form-container">
              <FeedBackForm />
            </div>
          </div>
        </div>
      </FullWrapper>
    );
  }
}

export default BottomBanner;
