import React from 'react';
import './style.sass';
import { NavLink } from 'react-router-dom';

const linkTop = [
    {
        link: '/allvacancies/',
        linkName: 'Вакансии',
    },
    {
        link: '/about',
        linkName: 'Об Агентстве',
    },
    {
        link: '/digital-learning',
        linkName: 'Развитие в команде',
    },
    {
        link: '/our-events',
        linkName: 'Мероприятия',
    },
];

const linkDownList = [
    // {
    //     link: '/gallery',
    //     linkName: 'Галерея'
    // },
    // {
    //     link: '/office',
    //     linkName: 'Офис'
    // },
    // {
    //     link: '/meetups',
    //     linkName: 'Митапы'
    // },
    {
        link: 'https://webpractik.ru/',
        linkName: 'webpractik.ru',
        isBlank: true,
    },
];

interface IProps {
    closeMenu: Function;
}

function MenuBurger({ closeMenu }: IProps) {
    const onNavigate = () => {
        closeMenu();
    };
    return (
        <div className="menu-burger">
            <div className="menu-burger__wrapper">
                <div className="menu-burger__grid">
                    <div className="menu-burger__top">
                        {linkTop.map(({ link, linkName }, index) => (
                            <div className="menu-burger__top-item" key={index}>
                                <NavLink
                                    to={link}
                                    activeClassName="active"
                                    className="menu-burger__top-link"
                                    onClick={onNavigate}
                                    dangerouslySetInnerHTML={{ __html: linkName }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuBurger;
