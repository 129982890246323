import React from 'react';

const PhotoSliderItem:React.FC<{ photo: string, index:string, onClick:any }> = ({photo,index, onClick}) => {
    return (
        <div onClick={onClick} className="photo-slider__item-in" style={{backgroundImage: `url(${photo})`}} key={index}>
        </div>
    );
};

export default PhotoSliderItem;
