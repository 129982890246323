import React, {Component} from 'react';

import TechListItem from "./tech-list-item";
import FullWrapper from '../../wrappers/full-wrapper';
import CommonTitle from '../../components/common-title';

import startTech1 from 'pic/tech-star.png';

import './style.sass';

interface IPropsTech {
    data: {
        title?: string;
        imgList: {
            caption?: string,
            url?: any,
            alternativeText?: string
        }
    }
}

class Tech extends Component<IPropsTech, {}> {
    render() {
        return (
            <div className="tech">
                <img src={startTech1} alt="" className="tech__star"/>
                <FullWrapper bigPaddings={true}>
                    <div className="tech__wrapper">
                        <div className="tech__left">
                            <CommonTitle className="tech__title" gradient={false}>{this.props.data.title}</CommonTitle>
                        </div>
                        <div className="tech__right">
                            <div className="tech-list">
                                {
                                    Object.entries(this.props.data.imgList).map((item, index) => (
                                        <TechListItem key={index} caption={item[1].caption} url={item[1].url} alternativeText={item[1].alternativeText}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </FullWrapper>
            </div>
        );
    }
}

export default Tech;